import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
  FormGroup,
  Form,
  InputGroup,
  Spinner,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//i18n
import { withTranslation } from "react-i18next"

//redux
import { getClientes, getEstadoMuestra, getEstadoPedido, getHojasCustodia, getPedidos, getUser } from "helpers/LabaquaHelper"
import LastMuestras from "components/Labaqua/Muestra/LastMuestras"
import ListPedidosComp from "components/Labaqua/Pedido/ListPedidosComp"
import i18n from "i18n"
import i18next from "i18next"
import ListHojasComp from "components/Labaqua/Hojas/ListHojasComp"
import { Link } from "react-router-dom"
import { Spanish } from "flatpickr/dist/l10n/es.js"

const ListHoja = props => {


  
 
  const [estado, setEstado] = useState([])
  const [clientes, setClientes] = useState([])
  const [hojas, setHojas] = useState()
  const [resultHojas, setResultHojas] = useState()
  const [loadingData, setLoadingData] = useState(true);
  const [language, setLanguage] = useState(i18next.language)
  i18n.on('languageChanged', function(value) {
    setLanguage(value);
  });

  const optionsPedido = [
    {
      key: false,
      value: "Todos"
    },
    {
      key: true,
      value: "Activos"
    },
  ]
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 2);
  const fromConverted = `${fromDate.getFullYear()}/${fromDate.getMonth()}/${fromDate.getDay() + 1}`;

  const [formSearch, setFormSearch] = useState({
    "desde": fromConverted,
    "hasta":null,
  });

  useEffect(() => {
    getData();
  }, [])

  async function filterData(){
    
  }

  function handlerChangeForm(field, value) {
    let formSearchChanged = formSearch;
    formSearchChanged[field] = value;
    let hojasFiltered = filterDateRange(hojas);
    setResultHojas(hojasFiltered)
    //setHojas(hojasResponse);
    //setFormSearch(formSearchChanged);
    //getData();
  }

  function getTextEstado(textos){
    let text =  textos.find( text => text.idioma == language);
    if(text.texto){
      return text.texto;
    }
    return "";
  }

  async function filterData(value){
    setLoadingData(true);
    const hojasResponse = await getHojasCustodia(value);
    setHojas(hojasResponse);
    setLoadingData(false);
    let hojasFiltered = filterDateRange(hojasResponse);
    setResultHojas(hojasFiltered)
  }

  function filterDateRange(hojas){
    let desde = new Date(formSearch.desde);
    let hasta = new Date(formSearch.hasta)
    if(formSearch.hasta == null){
      hasta = new Date(); 
    }

    let hojasFiltered = hojas.filter((item) => {
      return new Date(item.created_at).getTime() >= desde.getTime() &&
          new Date(item.created_at).getTime() <= hasta.getTime();
    });
    return hojasFiltered;
  }



  async function getData() {
    let clientesResponse = await getClientes();
    if(clientesResponse && clientesResponse.length > 0) {
      filterData(clientesResponse[0].clave)
    }
    setClientes(clientesResponse);
  }

  async function getHojaByInterlocutor(interlocutor){
    setLoadingData(true);
    const hojasResponse = await getHojasCustodia();
    setHojas(hojasResponse);
    setLoadingData(false);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Listado hojas")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Listado hojas")}`}
            breadcrumbItem={`${props.t("Listado hojas")}`}
          />

         {loadingData ? <Card><CardBody className="text-center"><Spinner className="ms-2 text-center" color="primary" /> </CardBody></Card> : null}
        
          <Row> 
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="row mb-4">
                  <Col sm={12} md={2}>
                  <FormGroup className="">
                    <Label>{props.t("Desde")}</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="YYYY/MM/DD"
                        options={{
                          altInput: true,
                          altFormat: "Y/m/d",
                          dateFormat: "Y-m-d",
                          defaultDate: fromConverted,
                          locale: {
                            ... Spanish
                          }
                        }}
                        readOnly={false}
                        onChange={(selectedDates, dateStr, instance) => handlerChangeForm('desde', dateStr)}
                      />
                    </InputGroup>

                  </FormGroup>
                </Col>

                <Col sm={12} md={2}>
                  <FormGroup className="">
                    <Label>{props.t("Hasta")}</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="YYYY/MM/DD"
                        options={{
                          altInput: true,
                          altFormat: "Y/m/d",
                          dateFormat: "Y-m-d",
                          locale: {
                            ... Spanish
                          }
                        }}
                        readOnly={false}
                        onChange={(selectedDates, dateStr, instance) => handlerChangeForm('hasta', dateStr)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col sm={12} md={3}>
                  <FormGroup className="">
                    <Label htmlFor="horizontal-email-Input" >
                        {props.t("Ciente")}
                    </Label>
                    <Input type="select" className="form-control"
                      onChange={(event) => {
                        const { selectedIndex } = event.target;
                        let value = "";
                        value = clientes[selectedIndex]?.clave
                        filterData(value);
                      }}>
                      {clientes && clientes.length ? clientes.map((cliente) => (
                        <option key={cliente.clave}>{cliente.nombre}</option>
                      )) : null}
                    </Input>
                  </FormGroup>
                </Col>
              </div>
                <Col lg="12">
                    <Link to={`hoja-detalle/v2/new`}>
                            <button className="btn btn-primary mb-3" type="submit">
                            {props.t("Nueva hoja")}
                            </button>
                    </Link> 
                    
                  </Col>
                </CardBody>
              </Card>
            </Col>

            <Col lg="12">
              {resultHojas && resultHojas.length ?
                <ListHojasComp size={30} hojas={resultHojas} title="" clientes={clientes}/>
                : null}
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  )
}

ListHoja.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ListHoja)
