import PropTypes, { string } from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getCategoriasDocumentos, getPedido, getPuntoMuestreo, getRepositorioDocumentos, getSinglemuestrasCustodia, getTiposAnalisis, saveMuestraHojaCustodia, updatemuestra } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import { Button } from "bootstrap"
import i18next from "i18next"
import i18n from "i18n";
import FormMuestrasEnvases from "./FormMuestrasEnvases"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"

const FormMuestrasHojas = (props) => {

  const [language, setLanguage] = useState(i18next.language);
  const [muestra, setMuestra] = useState({});
  const [puntos, setPuntos] = useState([]);
  const [tiposAnalisis, setTiposAnalisis] = useState([]);
  const [newEnvase, setNewEnvase] = useState(false);

  i18n.on('languageChanged', function (value) {
    setLanguage(value);
    getData();
  });

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    let puntosResponse = await getPuntoMuestreo(language);
    setPuntos(puntosResponse);
    let tiposAnalisisResponse = await getTiposAnalisis();
    setTiposAnalisis(tiposAnalisisResponse);
    if (typeof props.muestra != "undefined") {
      setMuestra(props.muestra);
    }
  }

  function addingEnvase() {
    if (!muestra.envases) {
      muestra.envases = [];
    }
    setNewEnvase(true);
  }

  function showPuntoByClave(clave) {
    if (puntos) {
      let punto = puntos.find((item) => {
        return item.clave == clave
      });
      if (punto) {
        return punto.descripcion;
      } else {
        return clave;
      }
    }
  }

  async function getMuestraByNumero(numero) {

  }

  // REVISAR
  async function getPedidoCheck(clavePedido){
    const pedido = await getPedido(clavePedido);
    // TODO Omplir el formulari amb les dades que torna el pedido
  }

  function savedData(envase) {
    setNewEnvase(false);
    if(!muestra.envases){
      muestra.envases = [];
    }
    muestra.envases.push(envase)
    setMuestra(muestra);

    //props.savedData(muestra);
  }

  function handlerChangeForm(field, value) {
    //2022-05-05T19:58:54.634Z"
    let formSearchChanged = muestra;
    formSearchChanged[field] = value;
    console.log(formSearchChanged);
    setMuestra(formSearchChanged);
  }

  async function saveMuestra() {
    if(props.idhoja){
      muestra.idHoja = props.idhoja;
      muestra.hojaId = props.idhoja;
    }
    if(props.typeMuestra){
      muestra.typeMuestra = props.typeMuestra;
    }
    //saveMuestraHojaCustodia(muestra);
    props.savedData(muestra);
  }




  function cancelEditing() {
    props.savedData();
  }

  function cancelEditingEnvase(){
    setNewEnvase(false);
  }


  return (
    <React.Fragment>
      <div className="">

        {/* Render Breadcrumb */}
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                {props.isNewMuestra ? <h5>Nueva muestra</h5> : <h5>Muestra solicitud: {muestra?.numeroSolicitud}</h5>}

                <div className="text-muted">
                  <Row>
                    <Col xs="12" md="6">
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Numero muestra")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            value={muestra?.muestraClave}
                            className="form-control"
                            id="horizontal-firstname-Input"
                            disabled={true}
                            onChange={(event) => {
                              handlerChangeForm('muestraClave', event.target.value);
                            }}
                            onBlur={(event) => {
                              handlerChangeForm('muestraClave', event.target.value);
                              getMuestraByNumero(event.target.value);
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Pedido")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            value={muestra.idPedido}
                            className="form-control"
                            id="horizontal-firstname-Input"
                            disabled={!props.isFromPedido}
                            onChange={(event) => {
                              handlerChangeForm('idPedido', event.target.value);
                            }}
                            onBlur={(event) => {
                              getPedidoCheck(event.target.value);
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Punto muestreo")}
                        </Label>
                        <Col sm={8}>

                          <Input type="select" className="form-control"
                            value={muestra?.puntoMuestreo}
                            onChange={(event) => {
                              const { selectedIndex } = event.target;
                              let value = puntos[selectedIndex]?.clave
                              handlerChangeForm('puntoMuestreo', value);
                            }}>
                            {puntos && puntos.length ? puntos.map((punto) => (
                              <option key={punto.clave}>{punto.descripcion}</option>
                            )) : null}
                          </Input>

                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Fecha tomador")}
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="YYYY/MM/DD"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y H:i K",
                                enableTime: true,
                                locale: {
                                  ...Spanish
                                }
                              }}
                              value={muestra.fechaTomador}
                              readOnly={false}
                              onChange={(selectedDates, dateStr, instance) => { handlerChangeForm('fechaTomador', selectedDates[0].toISOString()) }}
                            />
                          </InputGroup>
                        </Col>
                      </div>

                      {props.typeMuestra == 'tengoEtiqueta' || props.typeMuestra == 'tengoMuestra' ? 
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Tipo")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={muestra?.tipo}
                              disabled={!props.isNewMuestra}
                              onChange={(event) => {
                                handlerChangeForm('tipo', event.target.value);
                              }}
                            />
                          </Col>
                        </div>
                      : null}
                      
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Denominación")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra.denominacion}
                            onChange={(event) => {
                              handlerChangeForm('denominacion', event.target.value);
                            }}
                          />
                        </Col>
                      </div>

                    </Col>
                    <Col xs="12" md="6">
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Tomador")}
                        </Label>
                        <Col sm={8}>
                          {/*disabled={!props.isNewMuestra}*/}
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra?.tomador}
                            onChange={(event) => {
                              handlerChangeForm('tomador', event.target.value);
                            }}
                          />
                        </Col>
                      </div>


                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Referencia cliente")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra?.identificacionCliente}
                            onChange={(event) => {
                              handlerChangeForm('identificacionCliente', event.target.value);
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Estado envase")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra?.estadoEnvase}
                            disabled={!props.isNewMuestra}
                            onChange={(event) => {
                              handlerChangeForm('estadoEnvase', event.target.value);
                            }}
                          />
                        </Col>
                      </div>

                      {props.typeMuestra != 'tengoEtiqueta' && props.typeMuestra != 'tengoPedido' ? 
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("parametrosAnalizar")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={muestra?.parametrosAnalizar}
                              disabled={muestra?.idPedido?.length > 0}
                              onChange={(event) => {
                                handlerChangeForm('parametrosAnalizar', event.target.value);
                              }}
                            />
                          </Col>
                        </div>
                        : null
                      }

                      {props.typeMuestra != 'tengoPedido' ? 
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Instrucciones Analisis")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={muestra?.instruccionesAnalisis}
                              disabled={muestra?.idPedido?.length > 0}
                              onChange={(event) => {
                                handlerChangeForm('instruccionesAnalisis', event.target.value);
                              }}
                            />
                          </Col>
                        </div>
                      : null}

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Informacion informe")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra?.informacionInforme}
                            onChange={(event) => {
                              handlerChangeForm('informacionInforme', event.target.value);
                            }}
                          />
                        </Col>
                      </div>


                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Instrucción facturación")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra?.instruccionFacturacion}
                            disabled={muestra?.idPedido?.length > 0}
                            onChange={(event) => {
                              handlerChangeForm('instruccionFacturacion', event.target.value);
                            }}
                          />
                        </Col>
                      </div>


                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Otra información")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={muestra?.otraInformacion}
                            onChange={(event) => {
                              handlerChangeForm('otraInformacion', event.target.value);
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Tipo muestra clave")}
                        </Label>
                        <Col sm={8}>
                          <Input type="select" className="form-control"
                            value={muestra?.tipoMuestraClave}
                            onChange={(event) => {
                              const { selectedIndex } = event.target;
                              let value = tiposAnalisis[selectedIndex - 1]?.clave
                              handlerChangeForm('tipoMuestraClave', value);
                            }}>
                            <option key={""}>{props.t("No lo sé")}</option>
                            {tiposAnalisis && tiposAnalisis.length ? tiposAnalisis.map((tipo) => (
                              <option key={tipo.clave}>{tipo.descripcion}</option>
                            )) : null}
                          </Input>

                        </Col>
                      </div>

                      {muestra.tipoMuestraClave == "undefined" ?
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Tipo muestra")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={muestra?.tipoMuestra}
                              onChange={(event) => {
                                handlerChangeForm('tipoMuestra', event.target.value);
                              }}
                            />
                          </Col>
                        </div>
                        : null}
                    </Col>
                  </Row>
                  <Row>
                  </Row>
                  <div className="col-sm-12 col-md-6 mb-4">
                    <button
                      className="btn btn-primary btn-block"
                      type="submit" onClick={addingEnvase}
                    >
                      {props.t("Añadir envase")}
                    </button>

                    {newEnvase ? <FormMuestrasEnvases idmuestra={muestra.id} cancelEnvase={cancelEditingEnvase} typeMuestra={props.typeMuestra} savedData={savedData}></FormMuestrasEnvases> : null}

                    {muestra?.envases?.map((envase) => (
                      <Row key={envase.id}>
                        <FormMuestrasEnvases envase={envase} idmuestra={muestra.id} typeMuestra={props.typeMuestra} savedData={savedData}></FormMuestrasEnvases>
                      </Row>
                    ))}


                  </div>
                </div>

                {props.isNewMuestra ?
                  <div className="row">
                    <div className="col-sm-12 col-md-2 mb-4">
                      <button
                        className="btn btn-primary btn-block w-100"
                        type="submit" onClick={saveMuestra}
                      >
                        {props.t("Guardar")}
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-2 mb-4">
                      <button
                        className="btn btn-warning btn-block w-100"
                        type="submit" onClick={cancelEditing}
                      >
                        {props.t("Cancelar")}
                      </button>
                    </div>
                  </div>
                  :
                  null}

              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>

    </React.Fragment>
  )
}

FormMuestrasHojas.propTypes = {
  t: PropTypes.any,
  muestra: PropTypes.any,
  idhoja: PropTypes.any,
  isNewMuestra: PropTypes.any,
  savedData: PropTypes.any,
  isFromPedido: PropTypes.any,
  typeMuestra: PropTypes.any
}

export default withTranslation()(FormMuestrasHojas)
