import React from "react"
import PropTypes from 'prop-types'

import { Row, Col, Card, CardBody, Media } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../../assets/images/labaqua/icon-user-labaqua.png"
import profileImg from "../../../assets/images/profile-img.png"

const WelcomeComp = (props) => {
  const user = props.user;
  return (
    <React.Fragment>
       <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3 me-3">
                      <img
                        src={avatar1}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{user?.nombre}</h5>
                        <p className="mb-1">{user?.email}</p>
                        <p className="mb-1">{user?.codigo}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  user: PropTypes.any,
}
export default WelcomeComp
