import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
  FormGroup,
  Form,
  InputGroup,
  Spinner,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//i18n
import { withTranslation } from "react-i18next"

//redux
import { getClientes, getEstadoMuestra, getEstadoPedido, getPedidos } from "helpers/LabaquaHelper"
import LastMuestras from "components/Labaqua/Muestra/LastMuestras"
import ListPedidosComp from "components/Labaqua/Pedido/ListPedidosComp"
import i18n from "i18n"
import i18next from "i18next"
import { Spanish } from "flatpickr/dist/l10n/es.js"


const ListPedidos = props => {


  const [estado, setEstado] = useState([])
  const [clientes, setClientes] = useState([])
  const [pedidos, setPedidos] = useState()
  const [loadingData, setLoadingData] = useState(true);
  const [language, setLanguage] = useState(i18next.language)
  i18n.on('languageChanged', function(value) {
    setLanguage(value);
  });

  const optionsPedido = [
    {
      key: false,
      value: "Todos"
    },
    {
      key: true,
      value: "Activos"
    },
  ]
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);
  const fromConverted = `${fromDate.getFullYear()}/${fromDate.getMonth()}/${fromDate.getDay() + 1}`;
  const [formSearch, setFormSearch] = useState({
    "desde": fromConverted,
    "hasta": "",
    "soloactivos": false,
    "claveCliente": ""
  });

  useEffect(() => {
    getSelectablesFilters();
    getData();
  }, [])

  function handlerChangeForm(field, value) {
    let formSearchChanged = formSearch;
    formSearchChanged[field] = value;
    setFormSearch(formSearchChanged);
    getData();
  }

  function getTextEstado(textos){
    let text =  textos.find( text => text.idioma == language);
    if(text.texto){
      return text.texto;
    }
    return "";
  }
    
  async function getSelectablesFilters(){
    let estadoResponse = await getEstadoPedido();
    setEstado(estadoResponse);
    let clientesResponse = await getClientes();
    setClientes(clientesResponse);
  }

  async function getData() {
    const pedidosResponse = await getPedidos(formSearch);
    setPedidos(pedidosResponse);
    setLoadingData(false);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Listado pedidos")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Listado pedidos")}`}
            breadcrumbItem={`${props.t("Listado pedidos")}`}
          />

<Form>
            <Row className="mb-4">
              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label>{props.t("Desde")}</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="YYYY/MM/DD"
                      options={{
                        altInput: true,
                        altFormat: "Y/m/d",
                        dateFormat: "Y-m-d",
                        defaultDate: fromConverted,
                        locale: {
                          ... Spanish
                        }
                      }}
                      onChange={(selectedDates, dateStr, instance) => handlerChangeForm('desde', dateStr)}
                    />
                  </InputGroup>

                </FormGroup>
              </Col>

              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label>{props.t("Hasta")}</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="YYYY/MM/DD"
                      options={{
                        altInput: true,
                        altFormat: "Y/m/d",
                        dateFormat: "Y-m-d",
                        locale: {
                          ... Spanish
                        }
                      }}
                      onChange={(selectedDates, dateStr, instance) => handlerChangeForm('hasta', dateStr)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Estado")}
                  </Label>                   
                  <Input type="select" className="form-control" 
                  onChange={(event) => {
                    const { selectedIndex } = event.target;
                    handlerChangeForm('soloactivos',optionsPedido[selectedIndex].key);
                  }}>
                    {optionsPedido && optionsPedido.length ? optionsPedido.map((estado) => (
                       <option key={estado.key}>{props.t(estado.value)}</option>
                    )) :  null}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Cliente Pedido")}
                  </Label>                   
                  <Input type="select" className="form-control" 
                  onChange={(event) => {
                    const { selectedIndex } = event.target;
                    console.log(selectedIndex);
                    let value = "";
                    if(selectedIndex != 0){
                      value = clientes[selectedIndex-1]?.clave
                    }
                    handlerChangeForm('claveCliente', value);
                  }}>
                     <option key="">{props.t("Todos")}</option>
                    {clientes && clientes.length ? clientes.map((cliente) => (
                       <option key={cliente.clave}>{cliente.nombre}</option>
                    )) :  null}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {loadingData ? <Card><CardBody className="text-center"><Spinner className="ms-2 text-center" color="primary" /> </CardBody></Card> : null}
          <Row>
            <Col lg="12">
              {pedidos && pedidos.length ?
                <ListPedidosComp size={30} pedidos={pedidos} title="" />
                : null}
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  )
}

ListPedidos.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any
}

export default withTranslation()(ListPedidos)
