import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Input,
  Label,
  UncontrolledAlert
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";

import avatar from "../../../assets/images/labaqua/icon-user-labaqua.png"

// actions
import { editProfile, resetProfileFlag } from "../../../store/actions";
import { getConfiguration, login, updateConfiguration, updatePassword, updateUser } from "helpers/LabaquaHelper";
import { withTranslation } from "react-i18next";

const PerfilUsuario = props => {
  const dispatch = useDispatch();

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const [user, setUser] = useState(props.user);
  const [configuration, setConfiguration] = useState(null);
  const [errorForm, setErrorForm] = useState(false)
  const [updatingPassword, setUpdatingPassword] = useState(null)
  const [disabledEditUser, setDisabledEditUser] = useState(true)
  const [savingUser, setSavingUser] = useState(false)

  if (typeof props.user != 'undefined' && typeof user == 'undefined') {
    setUser(props.user);
  }

  async function updateField(event) {
    user[event.target.name] = event.target.value;
    setUser(user);
  }

  async function getConfig() {
    if (configuration == null) {
      let config = await getConfiguration();
      setConfiguration(config);
    }
  }

  useEffect(() => {
    getConfig();
  }, [])

  async function saveUser() {
    setSavingUser(true);
    await updateUser(user);
    setSavingUser(false)
    setDisabledEditUser(true);
  }

  async function updateNotifications(valueChanged) {
    configuration[valueChanged.target.id] = valueChanged.target.checked;
    setConfiguration(configuration);
    await updateConfiguration(configuration);
  }

  async function changePassword(event, values) {
    setUpdatingPassword(true);
    const result = await updatePassword(values.oldPassword, values.password);
    setUpdatingPassword(false);
    if (typeof result == "string" && result.length > 1) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
      let log = await login({ username: user.codigo, password: values.password });
      if (log && log.access_token) {
        window.localStorage.setItem('accessToken', log.access_token);
      }
    }
  }

  function enableEditUser(value) {
    setDisabledEditUser(value);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("My profile")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Labaqua" breadcrumbItem={props.t("My profile")} />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18 d-hidden">{props.t("My data")}</h4>
                      </div>
                    </Col>
                  </Row>
                  <Media>
                    <div className="ms-3 me-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail mr-1"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Inputa"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Codigo User")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              defaultValue={user?.codigo}
                              name='codigo'
                              className="form-control"
                              id="horizontal-firstname-Inputa"
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Inputa"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Name")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              defaultValue={user?.nombre}
                              name='nombre'
                              disabled={disabledEditUser}
                              className="form-control"
                              id="horizontal-firstname-Inputa"
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Inputa"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Email")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              defaultValue={user?.email}
                              name='email'
                              className="form-control"
                              id="horizontal-firstname-Inputa"
                              disabled={disabledEditUser}
                            />
                          </Col>
                        </div>
                      </div>
                    </Media>
                  </Media>
                  <Row>
                    {disabledEditUser ?
                      <div className="mt-3 d-grid col-xs-12 col-md-2">
                        <button
                          className="btn btn-warning btn-block btn-labaqua"
                          type="submit"
                          onClick={() => enableEditUser(false)}
                        >
                          {props.t("Edit")}
                        </button>
                      </div>
                      :
                      <>
                        <div className="mt-3 d-grid col-xs-12 col-md-2">
                          <button
                            className="btn btn-secondary btn-block"
                            type="submit"
                            onClick={() => enableEditUser(true)}
                          >
                            {props.t("Cancelar")}
                          </button>
                        </div>

                        <div className="mt-3 d-grid col-xs-12 col-md-2">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={savingUser}
                            onClick={() => saveUser(true)}
                          >
                            {props.t("Guardar")}
                          </button>
                        </div>
                      </>
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">{props.t("Change password")}</h4>
                      </div>
                    </Col>
                  </Row>

                  <div className="text-muted">

                    <AvForm
                      onValidSubmit={(e, v) => {
                        changePassword(e, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Current password")}
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="oldPassword"
                            type="password"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            errorMessage={props.t("Introduce actual Password")}
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("New password")}
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="password"
                            type="password"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            errorMessage={props.t("Introduce Password")}
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          {props.t("Repeat new password")}
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="password2"
                            type="password"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            errorMessage={props.t("Passwords not match")}
                            validate={{
                              required: { value: true },
                              match: { value: "password" },
                            }}
                          />
                        </Col>
                      </div>

                      {errorForm ?
                        <Alert color="danger" className="text-center mb-4 mt-1">{props.t("Error Actual Password")}</Alert>
                        :
                        <>
                          {updatingPassword != null ?
                            <UncontrolledAlert color="success" className="text-center mb-4 mt-1 alert-dismissible">{props.t("UpdatedPassword")}</UncontrolledAlert>
                            : null}
                        </>
                      }


                      <Row>
                        <Col xs={12} className="text-center">
                          <Button color="info" className="btn btn-info w-lg" disabled={updatingPassword}>
                            {props.t('Enviar')}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">{props.t("Notificaciones")}</h4>
                      </div>
                    </Col>
                  </Row>

                  <div className="text-muted">
                    {configuration ?
                      <Row>
                        <Col xs={12} lg={4} xl={4}>
                          <div className="form-check form-switch form-switch-md mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="notificarAlerta"
                              onChange={e => { updateNotifications(e) }}
                              defaultChecked={configuration.notificarAlerta}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notificarAlerta"
                            >
                              {props.t("notificarAlerta")}
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} xl={4}>
                          <div className="form-check form-switch form-switch-md mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="notificarComercial"
                              onChange={e => { updateNotifications(e) }}
                              defaultChecked={configuration.notificarComercial}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notificarComercial"
                            >
                              {props.t("notificarComercial")}
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} xl={4}>
                          <div className="form-check form-switch form-switch-md mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="notificarIncidencia"
                              onChange={e => { updateNotifications(e) }}
                              defaultChecked={configuration.notificarIncidencia}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notificarIncidencia"
                            >
                              {props.t("notificarIncidencia")}
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} xl={4}>
                          <div className="form-check form-switch form-switch-md mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="notificarInforme"
                              onChange={e => { updateNotifications(e) }}
                              defaultChecked={configuration.notificarInforme}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notificarInforme"
                            >
                              {props.t("notificarInforme")}
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} xl={4}>
                          <div className="form-check form-switch form-switch-md mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="notificarRecepcion"
                              onChange={e => { updateNotifications(e) }}
                              defaultChecked={configuration.notificarRecepcion}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notificarRecepcion"
                            >
                              {props.t("notificarRecepcion")}
                            </label>
                          </div>
                        </Col>
                      </Row>
                      : null}
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


PerfilUsuario.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any
}
export default withTranslation()(withRouter(PerfilUsuario));
