import PropTypes, { string } from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getCategoriasDocumentos, getPuntoMuestreo, getRepositorioDocumentos, getSinglemuestrasCustodia, getTipoEnvases, getTiposAnalisis, saveEnvaseaHojaCustodia, saveMuestraHojaCustodia, updatemuestra } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import { Button } from "bootstrap"
import i18next from "i18next"
import i18n from "i18n";

const FormMuestrasEnvases = (props) => {
  const [language, setLanguage] = useState(i18next.language);
  const [envase, setEnvase] = useState({});
  const [newEnvase, setNewEnvase] = useState(false);
  const [tiposEnvase, setTiposEnvase] = useState([]);

  i18n.on('languageChanged', function (value) {
    setLanguage(value);
    getData();
  });

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    if (typeof props.envase != "undefined") {
      setEnvase(props.envase);
    }
    let tiposEnvase = await getTipoEnvases(language);
    setTiposEnvase(tiposEnvase);
  }
  function addingEnvase() {
    setNewEnvase(true);
  }

  function handlerChangeForm(field, value) {
    let formSearchChanged = envase;
    formSearchChanged[field] = value;
    setEnvase(formSearchChanged);
  }

  async function saveEnvase() {
    setEnvase(envase);
    saveEnvaseaHojaCustodia(envase);
    props.savedData(envase);
  }


  function cancelEditing() {
    if(typeof props.cancelEnvase == "function"){
      props.cancelEnvase();
    }else {
      props.savedData();
    }
  }


  return (
    <React.Fragment>
      <div className="mt-4">

        {/* Render Breadcrumb */}
        <Row>
          <hr />
          <Col xl="12">
            {props.isNewEnvase ? <h5>Nuevo envase</h5> : <h5>Envase: {envase?.tipoEnvase}</h5>}


          
              <div className="text-muted">
                <Row>
              
                  <Col xs="12" md="6">
                    {props.typeMuestra != 'imprevista' ? 
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-12 col-form-label"
                        >
                          {props.t("Envase clave")}
                        </Label>
                        <Col sm={8}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-password-Input"
                            value={envase?.envaseClave}
                            onChange={(event) => {
                              handlerChangeForm('envaseClave', event.target.value);
                            }}
                          />
                        </Col>
                      </div>
                    : null}
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-12 col-form-label"
                      >
                        {props.t("Tipo envase clave")}
                      </Label>
                      <Col sm={8}>
                        <Input type="select" className="form-control"
                          value={envase?.tipoEnvaseClave}
                          onChange={(event) => {
                            const { selectedIndex } = event.target;
                            let value = tiposEnvase[selectedIndex]?.clave
                            handlerChangeForm('tipoEnvaseClave', value);
                          }}>
                          {tiposEnvase && tiposEnvase.length ? tiposEnvase.map((tipo) => (
                            <option key={tipo.clave}>{tipo.descripcion}</option>
                          )) : null}
                        </Input>

                      </Col>
                    </div>                  
                  </Col>
                </Row>

              </div>
            <div className="row">
              <div className="col-sm-12 col-md-2 mb-4">
                <button
                  className="btn btn-primary btn-block"
                  type="submit" onClick={saveEnvase}
                >
                  {props.t("Guardar")}
                </button>
              </div>
              <div className="col-sm-12 col-md-2 mb-4">
                <button
                  className="btn btn-warning btn-block"
                  type="submit" onClick={cancelEditing}
                >
                  {props.t("Cancelar")}
                </button>
              </div>
            </div>
          </Col>
        </Row>

      </div>

    </React.Fragment>
  )
}

FormMuestrasEnvases.propTypes = {
  t: PropTypes.any,
  envase: PropTypes.any,
  idmuestra: PropTypes.any,
  isNewEnvase: PropTypes.any,
  savedData: PropTypes.any,
  cancelEnvase: PropTypes.any,
  typeMuestra: PropTypes.any
}

export default withTranslation()(FormMuestrasEnvases)
