import React from "react"
import PropTypes, { any } from "prop-types"
import { Route, Redirect } from "react-router-dom"
import * as queryString from "query-string";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  user,
  ...rest
}) => (
 
  <Route
    {...rest}
    render={props => {
      const dataApp = queryString.parse(location.search);
      if(dataApp) {
        if(dataApp.pushtoken){
          localStorage.setItem("pushtoken", dataApp.pushtoken);
        }
        if(dataApp.deviceid){
          localStorage.setItem("deviceid", dataApp.deviceid);
        }
        if(dataApp.platform){
          localStorage.setItem('platform', dataApp.platform);
        }
      }

      if (isAuthProtected && !localStorage.getItem("accessToken")) {
        return (
          <Redirect 
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout user={user}>
          <Component {...props} user={user} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  user: PropTypes.any
}

export default Authmiddleware;
