import React from 'react'
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from "prop-types"
import moment from "moment";

/*import AlertaIcon from '../../assets/images/icons/AlertaIcon';
import AlertaIconActive from '../../assets/images/icons/AlertaIconActive'; */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDefaultsSizePages } from 'helpers/generic_helper';
import { Link } from 'react-router-dom';
/*
interface PropsInterface extends WithTranslation{
    muestras: Muestra[]
    alertas?: AlertaData[]
    size: number
    pagination?: boolean;
}
interface StateInterface {
    currentPage: number
}
*/

const LastMuestras = (props) => {

    const size = props.size;
    const title = props.title;
    const muestras = props.muestras;
    const alertas = props.alertas;
    const { SearchBar } = Search;

    const defaultSorted = [{
        dataField: 'fechaRecepcion',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: size,
        totalSize: muestras.length, // replace later with size(customers),
        custom: true,
    }

    const columns = [{
        dataField: 'identificador',
        text: props.t("N de analisis"),
        sort: true,
        formatter: (cell,row) => { return <Link to={`muestra-detalle/${row.clave}`}>{cell}</Link>}
    }, {
        dataField: 'alerta',
        text: props.t("Alerta"),
        value: "",
        sort: true,
        isDummyField: true,
        formatter: (cell,row) => { return checkAlerta(row)}
    },
     {
        dataField: 'pedidoClave',
        text: props.t("Pedido"),
        formatter: (cell,row) => { return <Link to={`pedido-detalle/${row.pedidoClave}`}>{row.pedidoDescripcion}</Link>},
        sort: true
    }, {
        dataField: 'referenciaCliente',
        text: props.t("Referencia cliente"),
        sort: true
    }, {
        dataField: 'denominacion',
        text: props.t("Denomination"),
        sort: true
    }, {
        dataField: 'fechaToma',
        text: props.t("Fecha Toma"),
        formatter: (cell,row) => { return row.fechaToma ? moment(row.fechaToma).format("DD-MM-YY hh:mm") : ""},
        sort: true
    }, {
        dataField: 'fechaRecepcion',
        text: props.t("Fecha Recepcion"),
        formatter: (cell,row) => { return row.fechaRecepcion ? moment(row.fechaRecepcion).format("DD-MM-YY hh:mm") : ""},
        sort: true
    }, {
        dataField: 'estadoTexto',
        text: props.t("Status"),
        sort: true,
        formatter: (cell,row) => { return props.t(cell);}
    },
    {
        dataField: 'estadoTexto',
        text: props.t("Informe"),
        sort: true,
        formatter: (cell,row) => { return row.hayDocumentos ? <Link to={`muestra-detalle/${row.clave}`}><i className="fas fa-file external-event"></i></Link> : ""}
    }];

    const sizePerPageList = getDefaultsSizePages(muestras.length);

    function checkAlerta(muestra) {
        if (muestra.numAlertas > 0) {
            return <><i className="fas fa-exclamation-triangle"></i></>;
        }
        return null;
    }

    function colorMuestra(estado) {
        let classEstado = "text-danger"
        if (estado == 'Terminada') {
            classEstado = 'text-success';
        } else if (estado == 'EnCurso') {
            classEstado = 'text-warning';
        }
        return classEstado;
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">{props.t(title)}</h4>
                        </div>
                    </Col>
                </Row>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='identificador'
                    columns={columns}
                    data={muestras}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='identificador'
                            columns={columns}
                            data={muestras}
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    {size > 5 ?
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        null}
                                </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                    )
                    }</PaginationProvider>

            </CardBody>
        </Card>
    )
}

LastMuestras.propTypes = {
    t: PropTypes.any,
    size: PropTypes.any,
    muestras: PropTypes.any,
    pagination: PropTypes.any,
    alertas: PropTypes.any,
    title: PropTypes.string
}
export default withTranslation()(LastMuestras)