import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch, useStore } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

import logo from "assets/images/labaqua/labaqua-icon.jpg"
import { getCategoriasDocumentos, login, registerDevice } from "helpers/LabaquaHelper"
import { withTranslation } from "react-i18next"

const Login = props => {
  const dispatch = useDispatch()
  const [error, setError] = useState(false);

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  async function loginUser(event, values) {
    let log = await login(values);
    console.log(values);
    if (log && log.access_token) {
      setError(false);
      window.localStorage.setItem('accessToken', log.access_token);
      if(localStorage.getItem('pushtoken') || localStorage.getItem('deviceid') || localStorage.getItem('platform')){
        let pushToken = localStorage.getItem('pushtoken');
        let deviceid = localStorage.getItem('deviceid');
        let user = values.email;
        let platform = localStorage.getItem('platform');
        let register = await registerDevice({pushToken, deviceid, user, platform})
      }
      props.history.push("/dashboard");
      location.reload();
    }
    if (log && log.message) {
      setError(true);
    }
    if (typeof log === 'undefined' || typeof log === 'string') {
      setError(true);
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Labaqua</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-labaqua-secondary">
                  <Row>
                    <Col xs={12} xl={10}>
                      <div className="text-white p-4">
                        <h5 className="text-white">{props.t('Iniciar sesion')}</h5>
                        <p>{props.t('Desc Login')}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">

                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-labaqua">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle logo-contain"
                            height="24"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        loginUser(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{props.t("Invalid credentials")}</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={props.t("Usuario")}
                          value=""
                          className="form-control"
                          placeholder={props.t("Usuario Enter")}
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label={props.t("Password")}
                          value=""
                          type="password"
                          required
                          placeholder={props.t("Password Enter")}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {props.t("Login")}
                        </button>
                      </div>

                      {/* 
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {props.t("Password forgot")}
                        </Link>
                      </div>
                      */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Labaqua
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(withRouter(Login))

Login.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}
