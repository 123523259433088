import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody} from "reactstrap";

// availity-reactstrap-validation

//redux

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";


// actions
import { withTranslation } from "react-i18next";

const Contacto = props => {

  useEffect(() => {

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/v2.js";
    script.async = true;
    script.charset = "utf-8"
    script.type = "text/javascript";
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      console.log("a");
       if (window.hbspt) {
        hbspt.forms.create({
          region: "na1",
          portalId: "20327574",
          formId: "89abd458-4b4c-41fc-8f37-c55e9442c3c7",
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Contacto")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Labaqua" breadcrumbItem={props.t("Contacto")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div id="hubspotForm"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


Contacto.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any
}
export default withTranslation()(withRouter(Contacto));
