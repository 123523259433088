import React from 'react'
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from "prop-types"
import moment from "moment";

/*import AlertaIcon from '../../assets/images/icons/AlertaIcon';
import AlertaIconActive from '../../assets/images/icons/AlertaIconActive'; */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDefaultsSizePages } from 'helpers/generic_helper';
import { Link } from 'react-router-dom';
import { downloadFileNode, getFacturaPDF, storeFileCache } from 'helpers/LabaquaHelper';
import FileSaver from 'file-saver';


const ListFacturasComp = (props) => {

    const size = props.size;
    const title = props.title;
    const facturas = props.facturas.DatosFacturas.item;
    const { SearchBar } = Search;

    const defaultSorted = [{
        dataField: 'fechaRecepcion',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: size,
        totalSize: facturas.length, // replace later with size(customers),
        custom: true,
    }

    const columns = [{
        dataField: 'Vbeln',
        text: props.t("Vbeln"),
        sort: true,
    }, 
    {
        dataField: 'Erdat',
        text: props.t("Erdat"),
        value: "",
        sort: true
    },
    {
        dataField: 'Netwr',
        text: props.t("Netwr"),
        value: "",
        sort: true,
        formatter: (cell,row) => { return row.Netwr + " " + row.Waerk}
    },
    {
        dataField: 'Vbeln',
        text: '',
        sort: false,
        formatter: (cell, row) => { return  <i className="fas fa-file-pdf external-event"></i> },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { downloadFile(row)},
          }
    },];

    const sizePerPageList = getDefaultsSizePages(facturas);

    async function downloadFile(doc) {
        let facturaPDF = await getFacturaPDF(doc.Vbeln);        
        const filename = `${doc.Vbeln}.pdf`;
        const downloadLink = document.createElement("a");
        const href = 'data:application/octet-stream;base64,' + facturaPDF.B64Encode;

        const storeObjFileCache = {
            base64: facturaPDF.B64Encode,
            claveDocumento: doc.Vbeln
       };

       storeFileCache(storeObjFileCache);

        const downloadObjectNode = {
            clave: doc.Vbeln,
            filename: doc.Vbeln,
            extension: 'pdf'
        };

        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+new URLSearchParams(downloadObjectNode).toString();
            window.history.pushState({path:newurl},'',newurl);
        }
        //downloadFileNode(new URLSearchParams(downloadObjectNode).toString())

        downloadLink.href = href;
        downloadLink.download = filename;
        downloadLink.click();       

    }    
    
    async function getData() {
        const facturasResponse = await getFacturas("1");
        setFacturas(facturasResponse);
        setLoadingData(false);
      }


    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">{props.t(title)}</h4>
                        </div>
                    </Col>
                </Row>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='identificador'
                    columns={columns}
                    data={facturas}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='identificador'
                            columns={columns}
                            data={facturas}
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    {size > 5 ?
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        null}
                                </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                    )
                    }</PaginationProvider>

            </CardBody>
        </Card>
    )
}

ListFacturasComp.propTypes = {
    t: PropTypes.any,
    size: PropTypes.any,
    facturas: PropTypes.any,
    pagination: PropTypes.any,
    title: PropTypes.string
}
export default withTranslation()(ListFacturasComp)