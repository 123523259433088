import axios from "axios"

//apply base url for axios
//const API_URL = "http://localhost/api/v1";
//const API_URL = "http://dev-node.labaqua.com/api/v1";
const API_URL = "https://app.labaqua.com/api/v1";


const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  (response) => {
    if (response.headers && response.headers.newtoken) {
      window.localStorage.setItem('accessToken', response.headers.newtoken);
    }
    return response;
  },
  (error) => {
    if (error.response.status == 401 || error.response.data == 'La sesion ha caducado, por favor entre en sesión de nuevo' || error.response.data == 'Se necesita token') {
      window.localStorage.removeItem('accessToken');
      if (window.location.pathname != '/login') {
        window.location.href = "/login";
      }
    }
    return error.response;

  }
)

export function getToken() {
  let token = window.localStorage.getItem('accessToken');
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axiosApi.defaults.headers.common['Content-Type'] = 'text/plain; charset=x-user-defined';
}

export async function get(url, config = {}) {
  getToken();
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  getToken(); 
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch(error => error)
}

export async function postPDF(url, data, config = {}) {
  getToken();
  let token = window.localStorage.getItem('accessToken');
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  config = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                'Authorization': `Bearer ${token}`
            }
        }
  console.log(config);

  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch(error => error)
}

export async function put(url, data, config = {}) {
  getToken();
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  getToken();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function registerDevice(values) {
  return await post('/push/register', {
    Token: values.pushToken,
    DeviceId: values.deviceid,
    AppId: "LabaquaApp",
    UserId: values.user,
    Platform: values.platform
  });
}

export async function login(values) {
  return await post('/auth', {
    username: values.email,
    password: values.password
  });
}

export async function getUser() {
  const userData = await post('/lims/usuario', {});
  if (userData && userData != 'Unauthorized') return userData;
  return null;
}

export async function getMuestras(params) {
  const muestraParams = new URLSearchParams(params);
  const muestras = await post('/lims/muestra?' + muestraParams, {});
  return muestras;
}

export async function getAlertas(params) {
  const alertasParams = new URLSearchParams(params);
  const alertas = await post('/lims/alerta?' + alertasParams, {});
  return alertas;
}

export async function getAlertasMuestra() {
  const today = getTodayString();
  const alertas = await post('/lims/alerta?desde=2000/01/01&hasta=' + today, {});
  return alertas;
}

export async function getEstadoMuestra() {
  const estado = await get('/lims/Traduccion/Estados/Muestra', {});
  return estado;
}

export async function getParametros() {
  const parametros = await post('/lims/parametro', {});
  return parametros;
}

export async function getEstadoPedido() {
  const estado = await get('/lims/Traduccion/Estados/Pedido', {});
  return estado;
}


export async function getPedidos(params) {
  const formatedParams = new URLSearchParams(params);

  const pedidos = await post('/lims/pedido?' + formatedParams, {});
  return pedidos;
}

export async function getMuestraFromPedido(clavePedido){
  const muestra = await post('/lims/Muestra/parahojacustodia/pedido/' + clavePedido, {});
  return muestra;
}

export async function releaseMuestraFromPedido(muestraClave){
  const muestra = await post('/lims/Muestra/liberaDeHojacCustodia/' + muestraClave, {});
  return muestra;
}

export async function getPedido(clave) {
  const pedido = await post('/lims/pedido/' + clave, {});
  return pedido;
}

export async function getClientes() {
  const pedido = await post('/lims/cliente/', {});
  return pedido;
}
export async function updateMuestra(muestra) {
  // decode muestra
  const muestraParams = new URLSearchParams(muestra);
  const muestras = await put('/lims/muestra?' + muestraParams, {});
  return muestras;
}

export async function updateUser(user) {
  // decode muestra
  const userParams = new URLSearchParams(user);
  const userResponse = await put('/lims/usuario?' + userParams, {});
  return userResponse;
}

export async function getLastMuestras() {
  const today = getTodayString();
  const from = getFromString();
  const muestras = await post('/lims/muestra?desde=' + from + '&hasta=' + today, {});
  return muestras;
}

export async function ultimasMuestras(numero = 5) {
  const today = getTodayString();
  const from = getFromString();
  const muestras = await post('/lims/muestra/ultimas', {});
  return muestras;
}
export async function ultimasAlertas(numero = 5) {
  const today = getTodayString();
  const from = getFromString();
  const muestras = await post('/lims/alerta/ultimas', {});
  return muestras;
}

export async function getMuestra(clave) {
  const muestras = await post('/lims/muestra/' + clave, {});
  return muestras;
}

export async function getMuestraIdentificador(identificador) {
  const muestra = await post('/lims/muestra/identificador/' + identificador, {});
  return muestra;
}

export async function getOfertasVivas(claveCliente) {
  const muestra = await post('/lims/oferta?clienteClave=' + claveCliente, {});
  return muestra;
}

export async function getPedidoByOferta(claveOferta) {
  const muestra = await post('/lims/pedido/oferta/' + claveOferta, {});
  return muestra;
}



export async function getMuestraWithEnvase(envase) {
  const muestra = await post('/lims/muestra/envase/' + envase, {});
  return muestra;
}

export async function getTiposAnalisis() {
  const tipoanalisis = await post('/lims/tipoanalisis/', {});
  return tipoanalisis;
}

export async function getTipoEnvases(lang) {
  const tipoanalisis = await post('/lims/tipoenvase/HojaCustodia?idiomaClave=' + lang, {});
  return tipoanalisis;
}


export async function getLastAlertas() {
  const today = getTodayString();
  const from = getFromString();
  const alertas = await post('/lims/alerta?desde=' + from + '&hasta=' + today, {});
  return alertas;
}

export async function getPuntoMuestreo(lang) {
  const puntos = await post('/lims/PuntoMuestreo/usuario?idiomaClave=' + lang, {});
  return puntos;
}


export async function updateLang(lang) {
  const userData = await post('/lims/usuario?idiomaClave=' + lang, {});
  if (userData && userData != 'Unauthorized') return userData;
  return null;
}

export async function updatePassword(oldPassword, newPassword) {
  const changedPassword = await post(`/lims/usuario/resetpassword?passwordActual=${oldPassword}&nuevoPassword=${newPassword}`, {});
  return changedPassword;
}

export async function getConfiguration() {
  const configuration = await post('/lims/usuarioconfiguracion/', {});
  return configuration;
}

export async function updateConfiguration(configuration) {
  // decode muestra
  const configurationParams = new URLSearchParams(configuration);
  const configResponse = await put('/lims/usuarioConfiguracion?' + configurationParams, {});
  return configResponse;
}

function languageFromi18(lang){
  if(lang == 0){
    return "es";
  }
  return "en";
}

export async function getHojasCustodia(interlocutorClave) {
  const hojas = await post(`/hoja-custodia/list`, {interlocutorClave: interlocutorClave});
  return hojas;
}

export async function storeHojaCustodia(hojaCustodia) {
  const hojas = await post(`/hoja-custodia/save-hoja`, hojaCustodia);
  return hojas;
}

export async function getSingleHojasCustodia(identificador) {
  const hoja = await post(`/hoja-custodia/single-hoja`, {id: identificador});
  return hoja;
}

export async function checkMuestraExistsDB(identificador) {
  const muestras = await post(`/hoja-custodia/check-muestra`, {identificadorMuestra: identificador});
  return muestras;
}

export async function saveMuestraHojaCustodia(muestra) {
  const muestraResponse = await post(`/hoja-custodia/save-muestra`, muestra);
  return muestraResponse;
}

export async function saveEnvaseaHojaCustodia(envase) {
  const envaseResponse = await post(`/hoja-custodia/save-envase`, envase);
  return envaseResponse;
}

export async function getRepositorioDocumentos(lang, categories) {
  const docs = await post(`/repositorio/docs`, {lang: languageFromi18(lang), category: categories});
  return docs;
}

export async function getCategoriasDocumentos() {
  const pedidos = await post('/lims/ArchivoDocumental', {});
  return pedidos;
}

export async function storeHojaCustodiaAPI(data) {
  const hoja = await post('/lims/Documento/hojacustodia', data);
  return hoja;
}

export async function getFacturaPDF(idFactura) {
  const facturas = await post('/sapfacturas/pdf?method=ZsdwsFacturasPdf', { Factura: idFactura });
  return facturas;
}

export async function getFacturas(filters) {
  const facturas = await post('/sapfacturas/list?method=ZsdwsFacturas', 
  /*
    {
      IErdatDesde: getFarDateFromString(),
      IErdatHasta: getTodaySap(),
      IInterlocutores: [
        { item: { Kunrg: "D16033323"} }],
    }*/
    filters
  );
  return facturas;
}



function getTodayString() {
  const today = new Date();
  const todayConverted = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDay() + 1}`;
  return todayConverted;
}

function getFromString() {
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 2);
  const todayConverted = `${fromDate.getFullYear()}/${fromDate.getMonth()}/${fromDate.getDay() + 1}`;
  return todayConverted;
}

function getTodaySap() {
  const today = new Date();
  const todayConverted = `${today.getFullYear()}-${padTo2Digits(today.getMonth() + 1)}-${padTo2Digits(today.getDay() + 1)}`;
  return todayConverted;
}

function getFarDateFromString() {
  const fromDate = new Date();
  fromDate.setFullYear(fromDate.getFullYear() - 10);
  const todayConverted = `${fromDate.getFullYear()}-${padTo2Digits(fromDate.getMonth())}-${padTo2Digits(fromDate.getDay() + 1)}`;
  return todayConverted;
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export async function downloadFileNode(params) {
  const file = await get('/files?' + params);
  return file;
}

export async function storeFileCache(params) {
  const file = await post('/cache-files',params);
  return file;
}
