import React from 'react'
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from "prop-types"
import moment from "moment";

/*import AlertaIcon from '../../assets/images/icons/AlertaIcon';
import AlertaIconActive from '../../assets/images/icons/AlertaIconActive'; */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDefaultsSizePages } from 'helpers/generic_helper';
import { Link } from 'react-router-dom';

const ListPedidosComp = (props) => {

    const size = props.size;
    const title = props.title;
    const pedidos = props.pedidos;
    const { SearchBar } = Search;

    const defaultSorted = [{
        dataField: 'fechaRecepcion',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: size,
        totalSize: pedidos.length, // replace later with size(customers),
        custom: true,
    }

    const columns = [
    {
        dataField: 'codigo',
        text: props.t("Codigo"),
        sort: true,
        formatter: (cell, row) => { return <Link to={`pedido-detalle/${row.clave}`}>{cell}</Link> }

    }, 
    {
        dataField: 'descripcion',
        text: props.t("Descripcion"),
        sort: true
    },{
        dataField: 'cantidad',
        text: props.t("Cantidad"),
        sort: true
    }, 
    {
        dataField: 'fechaCreacion',
        text: props.t("Fecha Creacion"),
        formatter: (cell,row) => { return row.fechaCreacion ? moment(row.fechaCreacion).format("DD-MM-YYYY") : ""},
        sort: true
    },
    {
        dataField: 'estadoTexto',
        text: props.t("Estado"),
        sort: true
    }, {
        dataField: 'cliente',
        text: props.t("Cliente"),
        sort: true,
        formatter: (cell, row) => { return cell?.nombre }
    }];

    const sizePerPageList = getDefaultsSizePages(pedidos.length);

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">{props.t(title)}</h4>
                        </div>
                    </Col>
                </Row>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='identificador'
                    columns={columns}
                    data={pedidos}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='identificador'
                            columns={columns}
                            data={pedidos}
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    {size > 5 ?
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        null}
                                </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                    )
                    }</PaginationProvider>

            </CardBody>
        </Card>
    )
}

ListPedidosComp.propTypes = {
    t: PropTypes.any,
    size: PropTypes.any,
    pedidos: PropTypes.any,
    pagination: PropTypes.any,
    title: PropTypes.string
}
export default withTranslation()(ListPedidosComp)