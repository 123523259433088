import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardBody,
} from "reactstrap"


// Pages Components
import WelcomeComp from "./WelcomeComp"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import LastMuestras from "components/Labaqua/Muestra/LastMuestras"
import { getCategoriasDocumentos, ultimasAlertas, ultimasMuestras } from "helpers/LabaquaHelper"
import ListAlertas from "components/Labaqua/Muestra/ListadosMuestra/ListAlertas"

const Homepage = props => {

  const user = props.user;

  const [loadingData, setLoadingData] = useState(true);
  const [muestras, setMuestras] = useState()
  const [alertas, setAlertas] = useState()

  useEffect(() => {
   getData();
    // get muestras
    // get alertas
  }, [])

  async function getData() {
    let muestrasResponse = await ultimasMuestras();
    if(muestrasResponse) {
      muestrasResponse = muestrasResponse.slice(Math.max(muestrasResponse.length - 5, 0))
      setMuestras(muestrasResponse);
    }
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 2);
    fromDate.setFullYear(fromDate.getFullYear() - 2);
    const fromConverted = `${fromDate.getFullYear()}/${fromDate.getMonth()}/${fromDate.getDay() + 1}`;
    const alertasResponse = await ultimasAlertas();
    if(alertasResponse){
      setAlertas(alertasResponse);
    }
    
    setLoadingData(false);
    let categories = await getCategoriasDocumentos();
    if(categories.length){
      window.localStorage.setItem('categoriesDocuments', categories);
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Homepage")}
            breadcrumbItem={props.t("Homepage")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp user={user} />
            </Col>
          </Row>
         
         
            <Row>
              <Col lg="12">
              {loadingData ? <Card><CardBody className="text-center"><Spinner className="ms-2 text-center" color="primary" /> </CardBody></Card> : null}
              {muestras && muestras.length > 0 ?
                <LastMuestras size={5} muestras={muestras} alertas={[]} title="LastMuestras"/>
                : null}
              </Col>
            </Row>

            <Row>
              <Col lg="12">
              {loadingData ? <Card><CardBody className="text-center"><Spinner className="ms-2 text-center" color="primary" /> </CardBody></Card> : null}
              {alertas && alertas.length > 0 ?
                <ListAlertas size={5} alertas={alertas} title="LastAlertas"/>
                : null}
              </Col>
            </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Homepage.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any
}

export default withTranslation()(Homepage)
