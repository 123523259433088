import PropTypes, { string } from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
  Spinner,
  FormGroup,
  InputGroup,
  Form,
} from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getClientes, getEstadoMuestra, getHojasCustodia, getLastAlertas, getLastMuestras, getMuestras, getParametros, getPuntoMuestreo } from "helpers/LabaquaHelper"
import LastMuestras from "components/Labaqua/Muestra/LastMuestras"
import i18next from "i18next";
import i18n from "i18n";
import ListAlertas from "components/Labaqua/Muestra/ListadosMuestra/ListAlertas";
import { Spanish } from "flatpickr/dist/l10n/es.js"


const ListAlertasPage = props => {
  
  i18n.on('languageChanged', function(value) {
    setLanguage(value);
  });

  const [language, setLanguage] = useState(i18next.language)
  const [parametro, setParametro] = useState([])
  const [clientes, setClientes] = useState([])
  const [alertas, setAlertas] = useState([]);
  const [puntos, setPuntos] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);
  const fromConverted = `${fromDate.getFullYear()}/${fromDate.getMonth()}/${fromDate.getDay() + 1}`;
  const [formSearch, setFormSearch] = useState({
    "desde": fromConverted,
    "hasta": "",
    "punto": "",
    "cliente": "",
    "parametro": "",
  });


  function handlerChangeForm(field, value) {
    let formSearchChanged = formSearch;
    formSearchChanged[field] = value;
    setFormSearch(formSearchChanged);
    getData();
  }

  function getTextEstado(textos){
    let text =  textos.find( text => text.idioma == language);
    if(text.texto){
      return text.texto;
    }
    return "";
  }

  useEffect(() => {
    //getLastData();
    getSelectablesFilters();
    getData();
  }, [])

  async function getSelectablesFilters(){
    let puntosResponse = await getPuntoMuestreo(language);
    setPuntos(puntosResponse);
    let parametroResponse = await getParametros();
    setParametro(parametroResponse);
    let clientesResponse = await getClientes();
    setClientes(clientesResponse);
  }

  async function getData() {
    const alertasResponse = await getAlertas(formSearch);
    setAlertas(alertasResponse);
    setLoadingData(false);
  }

  //punto=1&estado=3&desde=2021/12/10&hasta=2021/12/15

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Listado alertas")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Listado alertas")}`}
            breadcrumbItem={`${props.t("Listado alertas")}`}
          />
          <Form>
            <Row className="mb-4">
              <Col sm={12} md={2}>
                <FormGroup className="">
                  <Label>{props.t("Desde")}</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="YYYY/MM/DD"
                      options={{
                        altInput: true,
                        altFormat: "Y/m/d",
                        dateFormat: "Y-m-d",
                        defaultDate: fromConverted,
                        locale: {
                          ... Spanish
                        }
                      }}
                      readOnly={false}
                      onChange={(selectedDates, dateStr, instance) => handlerChangeForm('desde', dateStr)}
                    />
                  </InputGroup>

                </FormGroup>
              </Col>

              <Col sm={12} md={2}>
                <FormGroup className="">
                  <Label>{props.t("Hasta")}</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="YYYY/MM/DD"
                      options={{
                        altInput: true,
                        altFormat: "Y/m/d",
                        dateFormat: "Y-m-d",
                        locale: {
                          ... Spanish
                        }
                      }}
                      readOnly={false}
                      onChange={(selectedDates, dateStr, instance) => handlerChangeForm('hasta', dateStr)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col sm={12} md={2}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Parametros")}
                  </Label>
      
                   
                  <Input type="select" className="form-control" 
                  onChange={(event) => {
                    const { selectedIndex } = event.target;
                    let value = "";
                    if(selectedIndex != 0){
                      value = parametro[selectedIndex-1].clave;
                    }
                    handlerChangeForm('parametro', value);
                  }}>
                    <option key="">{props.t("Todos")}</option>
                    {parametro && parametro.length ? parametro.map((param) => (
                       <option key={param.clave}>{param.descripcion}</option>
                    )) :  null}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={12} md={2}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Ciente")}
                  </Label>                   
                  <Input type="select" className="form-control" 
                  onChange={(event) => {
                    const { selectedIndex } = event.target;
                    console.log(selectedIndex);
                    let value = "";
                    if(selectedIndex != 0){
                      value = clientes[selectedIndex-1]?.clave
                    }
                    handlerChangeForm('cliente', value);
                  }}>
                     <option key="">{props.t("Todos")}</option>
                    {clientes && clientes.length ? clientes.map((cliente) => (
                       <option key={cliente.clave}>{cliente.nombre}</option>
                    )) :  null}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={12} md={2}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Punto")}
                  </Label>                   
                  <Input type="select" className="form-control" 
                  onChange={(event) => {
                    const { selectedIndex } = event.target;
                    console.log(selectedIndex);
                    let value = "";
                    if(selectedIndex != 0){
                      value = puntos[selectedIndex-1]?.clave
                    }
                    handlerChangeForm('punto', value);
                  }}>
                     <option key="">{props.t("Todos")}</option>
                    {puntos && puntos.length ? puntos.map((punto) => (
                       <option key={punto.clave}>{punto.descripcion}</option>
                    )) :  null}
                  </Input>
                </FormGroup>
              </Col>


{/*
              <Col sm={12} md={2}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Punto")}
                  </Label>
                  <select className="form-control" 
                   onChange={(event) => {
                    const { selectedIndex } = event.target;
                    handlerChangeForm('punto',options[selectedIndex]);
                  }}>
                    <option>Large select</option>
                    <option>Small select</option>
                  </select>
                </FormGroup>
              </Col>
                */}
            </Row>
          </Form>

          {loadingData ? <Card><CardBody className="text-center"><Spinner className="ms-2 text-center" color="primary" /> </CardBody></Card> : null}
          <Row>
            <Col lg="12">
              {alertas && alertas.length ?
                <ListAlertas size={5} alertas={alertas}/>
                : null}
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  )
}

ListAlertasPage.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ListAlertasPage)
