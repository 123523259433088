import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
  Spinner,
  FormGroup,
  InputGroup,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getClientes, getFacturas, getLastAlertas, getLastMuestras, getMuestras, getUser, padTo2Digits } from "helpers/LabaquaHelper"
import LastMuestras from "components/Labaqua/Muestra/LastMuestras"
import ListFacturasComp from "components/Labaqua/Facturas/ListFacturasComp"

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"


const ListFacturas = props => {

  const [facturas, setFacturas] = useState()
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const fromDate = new Date();
  const hastaConverted = `${fromDate.getFullYear()}-${padTo2Digits(fromDate.getMonth())}-${padTo2Digits(fromDate.getDay() + 1)}`;

  fromDate.setMonth(fromDate.getMonth() - 3);
  const fromConverted = `${fromDate.getFullYear()}-${padTo2Digits(fromDate.getMonth())}-${padTo2Digits(fromDate.getDay() + 1)}`;
  const [formSearch, setFormSearch] = useState({
    IErdatDesde: fromConverted,
    IErdatHasta: hastaConverted,
    IInterlocutores: [
      { item: { Kunrg: cliente} }],
  });

  useEffect(() => {
    getSelectablesFilters();
    getData();
  }, [])

  async function getData() {
    setLoadingData(true);
    const user = await getUser();
    const facturasResponse = await getFacturas(formSearch);
    setFacturas(facturasResponse);
    setLoadingData(false);
  }

  async function getSelectablesFilters(){
    let clientesResponse = await getClientes();
    if(clientesResponse.length){
      formSearch.IInterlocutores[0].item.Kunrg = clientesResponse[0].codigo;
    }    
    setClientes(clientesResponse);
    setFormSearch(formSearch);
  }

  function handlerChangeForm(field, value) {
    let formSearchChanged = formSearch;
    formSearchChanged[field] = value;
    setFormSearch(formSearchChanged);
    getData();
  }

  function handlerSelect(value) {
    setCliente(value);
    formSearch.IInterlocutores[0].item.Kunrg = value;
    console.log(formSearch);
    setFormSearch(formSearch);
    getData();
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Listado facturas")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Listado facturas")}`}
            breadcrumbItem={`${props.t("Listado facturas")}`}
          />

<Form>
            <Row className="mb-4">
              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label>{props.t("Desde")}</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="YYYY/MM/DD"
                      options={{
                        altInput: true,
                        altFormat: "Y/m/d",
                        dateFormat: "Y-m-d",
                        defaultDate: fromConverted,
                        locale: {
                          ... Spanish
                        }
                      }}
                      readOnly={false}
                      onChange={(selectedDates, dateStr, instance) => handlerChangeForm('IErdatDesde', dateStr)}
                    />
                  </InputGroup>

                </FormGroup>
              </Col>

              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label>{props.t("Hasta")}</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="YYYY/MM/DD"
                      options={{
                        altInput: true,
                        altFormat: "Y/m/d",
                        dateFormat: "Y-m-d",
                        defaultDate: hastaConverted,
                        locale: {
                          ... Spanish
                        }
                      }}
                      readOnly={false}
                      onChange={(selectedDates, dateStr, instance) => handlerChangeForm('IErdatHasta', dateStr)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Ciente")}
                  </Label>                   
                  <Input type="select" className="form-control" 
                  onChange={(event) => {
                    const { selectedIndex } = event.target;
                    let value = clientes[selectedIndex]?.codigo;
                    handlerSelect(value);
                  }}>
                    {clientes && clientes.length ? clientes.map((cliente) => (
                       <option key={cliente.codigo}>{cliente.nombre}</option>
                    )) :  null}
                  </Input>
                </FormGroup>
              </Col>

{/*
              <Col sm={12} md={3}>
                <FormGroup className="">
                  <Label
                    htmlFor="horizontal-email-Input"
                  >
                    {props.t("Punto")}
                  </Label>
                  <select className="form-control" 
                   onChange={(event) => {
                    const { selectedIndex } = event.target;
                    handlerChangeForm('punto',options[selectedIndex]);
                  }}>
                    <option>Large select</option>
                    <option>Small select</option>
                  </select>
                </FormGroup>
              </Col>
                */}
            </Row>
          </Form>
          {loadingData ? <Card><CardBody className="text-center"><Spinner className="ms-2 text-center" color="primary" /> </CardBody></Card> : null}
            <Row>
              <Col lg="12">
              {facturas && facturas.DatosFacturas ?
                <ListFacturasComp size={30} facturas={facturas} title=""/>
                : null}
              </Col>
            </Row>
        
        </Container>
      </div>

    </React.Fragment>
  )
}

ListFacturas.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ListFacturas)
