import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody} from "reactstrap";

// availity-reactstrap-validation

//redux

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";


// actions
import { withTranslation } from "react-i18next";
import i18n from "i18n";
import i18next from "i18next";

const HubspotPedido = props => {
  i18n.on('languageChanged', function() {
    window.location.reload(false);
  });
  useEffect(() => {

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/v2.js";
    script.async = true;
    script.charset = "utf-8"
    script.type = "text/javascript";
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      console.log(i18next.language)
       if (window.hbspt) {
        hbspt.forms.create({
          region: "na1",
          portalId: "20327574",
          formId: i18next.language == 0 ? "c09da334-c7c0-4c8b-857e-d66037742e4e" :"3dd80dd7-cd88-4af6-ac19-76280847240a",
          target: '#hubspotForm'
        });
      }
    });
  }, []);


  function addScript(){
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Modificar pedido")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Labaqua" breadcrumbItem={props.t("Modificar pedido")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div id="hubspotForm"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


HubspotPedido.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any
}
export default withTranslation()(withRouter(HubspotPedido));
