import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Faqs = props => {
  const [activeTab, setactiveTab] = useState("1")

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>FAQs | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="FAQs" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1")
                      }}
                    >
                      <i className="bx bx-question-mark d-block check-nav-icon mt-4 mb-2"/>
                      <p className="font-weight-bold mb-4">{props.t("Facturación")} </p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2")
                      }}
                    >
                      <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2"/>
                      <p className="font-weight-bold mb-4">{props.t("Pedidos y muestras")}</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3")
                      }}
                    >
                      <i className="bx bx-support d-block check-nav-icon mt-4 mb-2"/>
                      <p className="font-weight-bold mb-4">{props.t("Informes")}</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        setactiveTab("4")
                      }}
                    >
                      <i className="bx bx-support d-block check-nav-icon mt-4 mb-2"/>
                      <p className="font-weight-bold mb-4">{props.t("Plazos")}</p>
                    </NavLink>
                  </NavItem>
                
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <CardTitle className="mb-5">
                          {props.t("Facturación")}
                        </CardTitle>
                        {[...Array(3)].map((x, i) =>
                          <Media className="faq-box mb-4" key={i}>
                          <div className="faq-icon me-3">
                            <i className="bx bx-help-circle font-size-20 text-success"/>
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                            {props.t("question_fact"+i)}
                            </h5>
                            <p className="text-muted">
                            {props.t("answer_fact"+i)}
                            </p>
                          </Media>
                        </Media>
                        )}
                      </TabPane>
                      <TabPane tabId="2">
                        <CardTitle className="mb-5">{props.t("Pedidos y muestras")}</CardTitle>

                        {[...Array(1)].map((x, i) =>
                          <Media className="faq-box mb-4" key={i}>
                          <div className="faq-icon me-3">
                            <i className="bx bx-help-circle font-size-20 text-success"/>
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                            {props.t("question_pedidos"+i)}
                            </h5>
                            <p className="text-muted">
                            {props.t("answer_pedidos"+i)}
                            </p>
                          </Media>
                        </Media>
                        )}

                        </TabPane>
                      <TabPane tabId="3">
                        <CardTitle className="mb-5">{props.t("Informes")}</CardTitle>
                        {[...Array(2)].map((x, i) =>
                          <Media className="faq-box mb-4" key={i}>
                          <div className="faq-icon me-3">
                            <i className="bx bx-help-circle font-size-20 text-success"/>
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                            {props.t("question_informes"+i)}
                            </h5>
                            <p className="text-muted">
                            {props.t("answer_informes"+i)}
                            </p>
                          </Media>
                        </Media>
                        )}
                      </TabPane>
                      <TabPane tabId="4">
                        <CardTitle className="mb-5">{props.t("Informes")}</CardTitle>
                        {[...Array(2)].map((x, i) =>
                          <Media className="faq-box mb-4" key={i}>
                          <div className="faq-icon me-3">
                            <i className="bx bx-help-circle font-size-20 text-success"/>
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                            {props.t("question_plazos"+i)}
                            </h5>
                            <p className="text-muted">
                            {props.t("answer_plazos"+i)}
                            </p>
                          </Media>
                        </Media>
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Faqs.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Faqs));