import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getCategoriasDocumentos, getpedido, getPedido, getRepositorioDocumentos, updatepedido } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import { Button } from "bootstrap"
import i18next from "i18next"

const PedidoDetalle = (props) => {

  const [pedido, setPedido] = useState();
  const [documents, setDocuments] = useState();
  const { clave } = useParams();
  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    const pedidoResponse = await getPedido(clave);
    setPedido(pedidoResponse);
    if(pedido && pedido.fechaCreacion){
      pedido.fechaCreacion =  moment(pedido.fechaCreacion).format("DD-MM-YY hh:mm");
    }
    let categories = localStorage.getItem('categoriesDocuments').replace(/'/g, "").split(',');
    const documentsResponse = await getRepositorioDocumentos(i18next.language, categories);
    setDocuments(documentsResponse);
  }

  const columnsDataTable = [
    {
      dataField: 'descripcion',
      text: props.t('Descripcion'),
      sort: true
    }
  ];

  const columnsDocDataTable = [{
    dataField: 'name',
    text: props.t('Nombre'),
    sort: true,
  }, {
    dataField: 'path',
    text: props.t('Archivo'),
    sort: true,
    formatter: (cell, row) => { return <a href={row.path} target="_blank" rel="noreferrer"><i className="fas fa-file external-event" ></i></a>;},
    events: {
        onClick: (e, column, columnIndex, row, rowIndex) => { downloadFile(row)},
      }
  },
  {
    dataField: 'category',
    text: props.t('Category'),
    sort: true
  }
  ];


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{`${props.t("Pedido ")} ${clave}`} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Pedido")}`}
            breadcrumbItem={`${props.t("Pedido")}`}
          />
          <Row>
          <Col xl="3" xs="12">
          <Link to={`/modificacion-pedido`}><button className="btn btn-primary btn-block mb-2">{props.t("Modificar pedido")}</button></Link>
          </Col>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="text-muted">
                  
                    <Row>
                      <Col xs="12" md="6">
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Codigo")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              value={pedido?.codigo}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              disabled
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Descripcion")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={pedido?.descripcion}
                              disabled
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Cantidad")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={pedido?.cantidad}
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Fecha Creacion")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={pedido?.fechaCreacion}
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Interlocutor Nombre")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={pedido?.interlocutor?.nombre}
                              disabled
                            />
                          </Col>
                        </div>

                      </Col>
                      <Col xs="12" md="6">
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Oferta")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              value={pedido?.oferta?.descripcion}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              disabled
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Legislacion")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-email-Input"
                              value={pedido?.legislacion}
                              disabled
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Estado")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={pedido?.estadoTexto}
                              disabled
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Cliente Nombre")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={pedido?.cliente?.nombre}
                              disabled
                            />
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {pedido && pedido.lineas ?
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <h5 className="mb-4">{props.t('Lineas')}</h5>
                    <div className="table-responsive">
                      <BootstrapTable keyField='clave' data={pedido?.lineas} columns={columnsDataTable}
                        rowClasses="bg-white  pt-4 pb-4 border-bottom-blue-labaqua border-with-0-5"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            : null}

{documents && documents.length?
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <h5 className="mb-4">{props.t('Documentos')}</h5>
                    <div className="table-responsive">
                      <BootstrapTable keyField='id' data={documents} columns={columnsDocDataTable}
                        rowClasses="bg-white  pt-4 pb-4 border-bottom-blue-labaqua border-with-0-5"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            : null}
        </Container>
      </div>

    </React.Fragment>
  )
}

PedidoDetalle.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(PedidoDetalle)
