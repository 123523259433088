import PropTypes, { string } from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { Prompt } from 'react-router'

//redux
import { getAlertas, getCategoriasDocumentos, getPedido, getPuntoMuestreo, getRepositorioDocumentos, getSinglemuestrasCustodia, getTiposAnalisis, saveMuestraHojaCustodia, updatemuestra } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import { Button } from "bootstrap"
import i18next from "i18next"
import i18n from "i18n";
import FormMuestrasEnvases from "../Hoja-detalle/FormMuestrasEnvases"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"

const FormMuestrasHojaV2 = (props) => {

  const [language, setLanguage] = useState(i18next.language);
  const [muestra, setMuestra] = useState({});
  const [puntos, setPuntos] = useState([]);
  const [tiposAnalisis, setTiposAnalisis] = useState([]);
  const [newEnvase, setNewEnvase] = useState(false);
  const [envasesFromLims, setEnvasesFromLims] = useState(true);


  const typeFormMuestra = props.typeFormMuestra;
  muestra.identificador = muestra.identificadorMuestra;

  const user = props.user;
  i18n.on('languageChanged', function (value) {
    setLanguage(value);
    getData();
  });

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    if (typeof props.muestra != "undefined") {
      setMuestra(props.muestra);
    }
  }

  function addingEnvase() {
    if (!muestra.envases) {
      muestra.envases = [];
    }
    setNewEnvase(true);
  }

  function showPuntoByClave(clave) {
    if (puntos) {
      let punto = puntos.find((item) => {
        return item.clave == clave
      });
      if (punto) {
        return punto.descripcion;
      } else {
        return clave;
      }
    }
  }

  async function getMuestraByNumero(numero) {

  }

  // REVISAR
  async function getPedidoCheck(clavePedido){
    const pedido = await getPedido(clavePedido);
    // TODO Omplir el formulari amb les dades que torna el pedido
  }

  function savedData(envase) {
    setNewEnvase(false);
    if(!muestra.envases){
      muestra.envases = [];
    }
    muestra.envases.push(envase)
    setMuestra(muestra);

    //props.savedData(muestra);
  }

  function handlerChangeForm(field, value) {
    //2022-05-05T19:58:54.634Z"
    let formSearchChanged = muestra;
    formSearchChanged[field] = value;
    console.log(formSearchChanged);
    setMuestra(formSearchChanged);
  }

  function updateField(){

  }




  function cancelEditingEnvase(){
    setNewEnvase(false);
  }


  return (
    <React.Fragment>
      <Row>
            <Col xl="12">
              <Card style={{background: "white", boxShadow: "none", marginBottom: 0}}>
                <CardBody style={{paddingBottom: "0px"}}>
                  <Row>
                    {typeFormMuestra == 'noData' ? 
                      <div className="text-muted">
                        <Row className="row mb-4">
                          <Col xs="12" md="12">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Denominacion")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.denominacion}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Tipo analisis")}
                              </Label>
                              <Col sm={8} md={8}>
                              <p>{muestra?.tipoDeAnalisis}</p>

                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Parametros a analizar")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.parametrosAnalizar}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Envases muestra")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.envasesMuestra}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Nº envases")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.numeroEnvases}</p>
                              </Col>
                            </div>
                          </Col>
                          <Col xs="12" md="12">
                            <div className="row mb-4">
                                {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Fecha toma")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{moment(muestra?.fechaToma).format("DD-MM-YY HH:mm")}</p>
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones informe")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.informacionClienteInforme}</p>
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones laboratorio")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.observacionesLaboratorio}</p>
                              </Col>
                            </div>
                            {user?.interno ? 
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones estado envases Labaqua")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.observacionesEstadoLaboratorio}</p>
                              </Col>
                            </div>
                            : <></>}
                          </Col>
                        </Row>
                      </div>
                    
                    : <></>}
                    {typeFormMuestra == 'limsData' ? 
                    <>
                       <div className="text-muted">
                        <Row className="row mb-4">
                          <Col xs="12" md="12">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Número Muestra")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.identificador}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Oferta")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.ofertaDescripcion}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Pedido")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.pedidoDescripcion}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Punto de muestreo")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.puntoDeMuestreo}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Tipo de analisis")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.tipoDeAnalisis}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Parámetros a analizar")}
                              </Label>
                              <Col sm={8} md={8}>
                              <p>{muestra?.parametrosAnalizar}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Envases muestra")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.envasesMuestra}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Nº envases")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.numeroEnvases}</p>
                              </Col>
                            </div>
                          </Col>
                          <Col xs="12" md="12">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Denominacion")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.denominacion}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                                {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                                <Label
                                  htmlFor="horizontal-email-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  {props.t("Fecha toma")}
                                </Label>
                                <Col sm={8} md={8}>
                                  <p>{moment(muestra?.fechaToma).format("DD-MM-YY HH:mm")}</p>
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Instrucciones análisis")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra.instruccionesAnalisis == 'tomaSimple' ? props.t("Toma simple") : props.t("Toma compuesta")} </p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Parámetros extra")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.parametrosExtra}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones informe")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.informacionClienteInforme}</p>
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones laboratorio")}
                              </Label>
                              <Col sm={8} md={8}>
                                <p>{muestra?.observacionesLaboratorio}</p>
                              </Col>
                            </div>
                            {user?.interno ? 
                             <div className="row mb-4">
                             {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                             <Label
                               htmlFor="horizontal-email-Input"
                               className="col-sm-3 col-form-label"
                             >
                               {props.t("Observaciones estado envases Labaqua")}
                             </Label>
                             <Col sm={8} md={8}>
                              <p>{muestra?.observacionesEstadoLaboratorio}</p>
                             </Col>
                           </div>
                           :<></>}
                           
                          </Col>
                        </Row>
                      </div>
                    </>
                    : 
                      <></> 
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
           </Row> 
    </React.Fragment>
  )
}

FormMuestrasHojaV2.propTypes = {
  t: PropTypes.any,
  muestra: PropTypes.any,
  typeFormMuestra: PropTypes.any,
  user: PropTypes.any
}

export default withTranslation()(FormMuestrasHojaV2)
