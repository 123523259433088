import React from 'react'
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from "prop-types"
import moment from "moment";

/*import AlertaIcon from '../../assets/images/icons/AlertaIcon';
import AlertaIconActive from '../../assets/images/icons/AlertaIconActive'; */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDefaultsSizePages } from 'helpers/generic_helper';
import { Link } from 'react-router-dom';

const ListHojasComp = (props) => {

    const size = props.size;
    const title = props.title;
    const clientes = props.clientes;
    const hojas = props.hojas;
    const { SearchBar } = Search;

    const defaultSorted = [{
        dataField: 'fechaRecepcion',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: size,
        totalSize: hojas.length, // replace later with size(customers),
        custom: true,
    }

    function getClienteNameIfPossible(clienteIdentificador){
        let clienteFind = clientes.find(x => x.clave === clienteIdentificador);
        if(clienteFind){
            return clienteFind.nombre;
        }
        return clienteIdentificador;
    }

    const columns = [
        {
            dataField: 'id',
            text: props.t("Id"),
            sort: true,
            formatter: (cell, row) => { return <Link to={`hoja-detalle/v2/${row.id}`}>{cell}</Link> }

        }, 
        {
            dataField: 'Cliente',
            text: props.t("Cliente"),
            formatter: (cell, row) => { return getClienteNameIfPossible(row.interlocutorClave) },
        }
    ];

    const sizePerPageList = getDefaultsSizePages(hojas.length);

    return (
        <Card>
            <CardBody>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='identificador'
                    columns={columns}
                    data={hojas}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='identificador'
                            columns={columns}
                            data={hojas}
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    {size > 5 ?
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        null}
                                </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                    )
                    }</PaginationProvider>

            </CardBody>
        </Card>
    )
}

ListHojasComp.propTypes = {
    t: PropTypes.any,
    size: PropTypes.any,
    hojas: PropTypes.any,
    pagination: PropTypes.any,
    clientes: PropTypes.any,
    title: PropTypes.string
}
export default withTranslation()(ListHojasComp)