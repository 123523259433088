import React from 'react'
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from "prop-types"

/*import AlertaIcon from '../../assets/images/icons/AlertaIcon';
import AlertaIconActive from '../../assets/images/icons/AlertaIconActive'; */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDefaultsSizePages } from 'helpers/generic_helper';
import { Link } from 'react-router-dom';
/*
interface PropsInterface extends WithTranslation{
    resultados: Muestra[]
    alertas?: AlertaData[]
    size: number
    pagination?: boolean;
}
interface StateInterface {
    currentPage: number
}
*/

const ListResultados = (props) => {

    const size = props.size;
    const resultados = props.resultados;
    const { SearchBar } = Search;

    const defaultSorted = [{
        dataField: 'estado',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: size,
        totalSize: resultados.length, // replace later with size(customers),
        custom: true,
    }

    const columns = [
    {
        dataField: 'parametro',
        text: props.t("Parameter"),
        value: "",
        sort: true,
    },
    {
        dataField: 'valor',
        text: props.t("Valor"),
        sort: true
    }, {
        dataField: 'unidades',
        text: props.t("Units"),
        sort: true
    }
    , {
        dataField: 'limites',
        text: props.t("Limites"),
        sort: true
    },
    {
        dataField: 'incertidumbre',
        text: props.t("Incertidumbre"),        
        sort: true
    }
    ,
    {
        dataField: 'estado',
        text: props.t("Status"),        
        sort: true,
        formatter: (cell,row) => { return props.t(row.estadoTexto);}
    }
    ];

    const sizePerPageList = getDefaultsSizePages(resultados.length);

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">{props.t("Parametros")}</h4>
                        </div>
                    </Col>
                </Row>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='clave'
                    columns={columns}
                    data={resultados}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='clave'
                            columns={columns}
                            data={resultados}
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                     <Row className="mb-2">
                                            <Col md="4">
                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar
                                                            {...toolkitProps.searchProps}
                                                        />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                    )
                    }</PaginationProvider>

            </CardBody>
        </Card>
    )
}

ListResultados.propTypes = {
    t: PropTypes.any,
    size: PropTypes.number,
    resultados: PropTypes.any
}
export default withTranslation()(ListResultados)