import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
  Alert
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getCategoriasDocumentos, getClientes, getMuestra, getMuestraIdentificador, getMuestras, getRepositorioDocumentos, getSingleHojasCustodia, getUser, storeHojaCustodia, storeHojaCustodiaAPI, ultimasMuestras, updatehoja } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import { Button } from "bootstrap"
import i18next from "i18next"
import FormMuestrasHoja from "./FormMuestrasHoja"

import Html from 'react-pdf-html';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import FormMuestrasEnvases from "./FormMuestrasEnvases"



const HojaDetalle = (props) => {

  const [typeMuestra, setTypeMuestra] = useState('');
  const [envase, setEnvase] = useState({envaseClave: ""})
  const [hoja, setHoja] = useState({});
  const [hasEtiqueta, setHasEtiqueta] = useState(false);
  const [muestrasListado, setMuestrasListados] = useState([]);
  const [muestraChanged, setMuestraChanged] = useState();
  const [documents, setDocuments] = useState();
  const [newMuestra, setNewMuestra] = useState(false);
  const [hasPedido, setHasPedido] = useState(false);
  const [showNewMuestra, setShowNewMuestra] = useState(false);
  const [showDesplegableMuestras, setShowDesplegableMuestras] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [editHoja, setEditHoja] = useState(false);
  const [errorSendingHoja, setErrorSendingHoja] = useState(false);
  const [okSendingHoja, setOkSendingHoja] = useState(false);
  const { clave } = useParams();
  const [user, setUser] = useState();
  let base64pdf = '';
  useEffect(() => {
    getData();
  }, [])

  function addedMuestras(data){
    if(!hoja.muestras){
      hoja.muestras = [];
    }
    hoja.muestras.push(data);
    setHoja(hoja);
    setNewMuestra(false);
  }

  async function getData() {
    setNewMuestra(false);
    if (clave != 'new') {
      const hojaResponse = await getSingleHojasCustodia(clave);
      setHoja(hojaResponse);
    } else {
      setEditHoja(true);
    }
    let clientesResponse = await getClientes();
    setClientes(clientesResponse);
    let user = await getUser();
    if (user) {
      setUser(user);
    }
  }

  function addingHoja() {
    setNewMuestra(true);
  }

  async function showMuestra() {
    setShowNewMuestra(true);
    let muestrasResponse = await ultimasMuestras(10);
    if (muestrasResponse) {
      setMuestrasListados(muestrasResponse);
    }
  }

  function showEnvaseForm() {
    setEnvase({envaseClave :""});
    setShowDesplegableMuestras(false);
    setShowNewMuestra(false);
    setHasPedido(false);
    setNewMuestra(false);
    setHasEtiqueta(true);
  }

  function showMuestraFormInsert(){
    setShowNewMuestra(false);
    setHasPedido(false);
    setNewMuestra(false);
    setHasEtiqueta(false);
    setShowDesplegableMuestras(true)
  }

  function insertEnvase(){
    if(!hoja.muestras){
      hoja.muestras = [];
    }
    hoja.muestras.push({envases: [envase]});

    console.log(hoja);
    setHoja(hoja);
    setEnvase({envaseClave :""});
    setHasEtiqueta(false);
  }

  function addingMuestra() {
    setNewMuestra(true);
  }

  function isFromPedido(){
    setTypeMuestra('tengoPedido')
    setShowDesplegableMuestras(false);
    setShowNewMuestra(false);
    setHasEtiqueta(false);
    setHasPedido(true);
    setNewMuestra(true);
  }

  async function insertMuestraFromSelect() {
    setShowDesplegableMuestras(false);
    setShowNewMuestra(false);
    setNewMuestra(true);
    const muestraResponse = await getMuestraIdentificador(muestraChanged);
    if (muestraResponse) {
      if (typeof muestraResponse != 'string') {

        if (muestraResponse && muestraResponse.fechaRecepcion) {
          muestraResponse.fechaRecepcion = moment(muestraResponse.fechaRecepcion).format("DD-MM-YY hh:mm");
        }

        const muestraLes = {};
        muestraLes.isNewMuestra = true;
        muestraLes.idPedido = muestraResponse.pedidoClave;
        muestraLes.muestraClave = muestraResponse.clave;
        muestraLes.puntoMuestreo = muestraResponse.puntoDeMuestreo;
        muestraLes.fechaTomador = muestraResponse.fechaToma ? new Date(muestraResponse.fechaToma).toISOString() : null;
        muestraLes.identificacionCliente = muestraResponse.referenciaCliente ? muestraResponse.referenciaCliente : user?.clave;
        muestraLes.denominacion = muestraResponse.denominacion;
        muestraLes.tomador = user?.nombre;
        if (!hoja.muestras) {
          hoja.muestras = [];
        }
        hoja.muestras.push(muestraLes);
        setHoja(hoja);
        setNewMuestra(false);
      }
    }
  }

  function handlerChangeForm(field, value) {
    let formSearchChanged = hoja;
    formSearchChanged[field] = value;
    setHoja(formSearchChanged);
  }

  async function storeHoja() {
    console.log(hoja);
    hoja.usuario = user.clave;
    const hojaSaved = await storeHojaCustodia(hoja);
    console.log(hojaSaved);
  }

  async function sendHoja() {
    setOkSendingHoja(false);
    const hojaResponse = await getSingleHojasCustodia(clave);
    let hojaAPI = {};
    hojaAPI.id = hoja.id.toString();
    hojaAPI.usuario = hoja.usuario;
    hojaAPI.interlocutorClave = hoja.interlocutor;
    //  hojaAPI.pedidoClave MOURE a MUESTRA?
    hojaAPI.pedidoClave = hoja.pedidoClave;
    hojaAPI.documentoGenerado = {
      "formato": "pdf",
      "descripcion": hoja.id.toString(),
      "contenidoB64": base64pdf,
    }
    hojaAPI.muestras = [];
    for (let muestraOriginal of hojaResponse.muestras) {
      let muestra = {};
      muestra.fechaToma = muestraOriginal.fechaTomador;
      muestra.tomador = muestraOriginal.tomador;
      muestra.referenciaCliente = muestraOriginal.identificacionCliente;
      muestra.denominacion = muestraOriginal.denominacion;
      muestra.puntoMuestreoClave = muestraOriginal.puntoMuestreo;
      muestra.informacionParaInforme = muestraOriginal.informacionInforme;
      muestra.tipoMuestraClave = muestraOriginal.tipoMuestra;
      muestra.tipoMuestra = muestraOriginal.tipo;
      muestra.tipo = muestraOriginal.tipo;
      muestra.parametrosAnalizar = muestraOriginal.parametrosAnalizar;
      muestra.instruccionesAnalisis = muestraOriginal.instruccionesAnalisis;
      muestra.instruccionesFacturacion = muestraOriginal.instruccionFacturacion;
      muestra.otraInformacion = muestraOriginal.otraInformacion;
      //news to add in NodeJS
      muestra.muestraClave = muestraOriginal.muestraClave;
      muestra.tipoMuestraClave = muestraOriginal.tipoMuestraClave;
      muestra.tipoMuestra = muestraOriginal.tipoMuestra;
      muestra.envases = [];
      if (muestraOriginal.envases) {
        for (let envaseOriginal of muestraOriginal.envases) {
          let envase = {};
          envase.id = envaseOriginal.id;
          // Check in nodejs
          envase.envaseClave = envaseOriginal.envaseClave
          envase.tipoEnvaseClave = envaseOriginal.tipoEnvaseClave;
          envase.tipoEnvase = envaseOriginal.tipoEnvase;
          muestra.envases.push(envase);
        }
      }

      hojaAPI.muestras.push(muestra);
    }
    let storedHoja = await storeHojaCustodiaAPI(hojaAPI);
    if (storedHoja.errors) {
      setErrorSendingHoja(true);
    } else {
      setOkSendingHoja(true);
    }
  }

  /* 
  function printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
  }
  */

  function generatePDF() {
    setErrorSendingHoja(false);
    setGeneratingPDF(true);
    var quotes = document.getElementById("factura");
    html2canvas(quotes).then((canvas) => {
      //! MAKE YOUR PDF
      var pdf = new jsPDF('p', 'pt', 'a4',false , true);

      for (var i = 0; i <= quotes.clientHeight / 980 * 2.5; i++) {
        //! This is all just html2canvas stuff
        var srcImg = canvas;
        var sX = 0;
        var sY = 980 * 2.5 * i; // start 980*2.5 pixels down for every new page
        var sWidth = 1200 * 1.15;
        var sHeight = 980 * 2.5;
        var dX = 0;
        var dY = 0;
        var dWidth = 1200 * 1.15;
        var dHeight = 980 * 1.1;

        window.onePageCanvas = document.createElement("canvas");
        onePageCanvas.setAttribute('width', 1200 * 1.15);
        onePageCanvas.setAttribute('height', 980 * 1.1);
        var ctx = onePageCanvas.getContext('2d');
        // details on this usage of this function: 
        // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
        ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

        // document.body.appendChild(canvas);
        var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

        var width = onePageCanvas.width;
        var height = onePageCanvas.clientHeight;

        //! If we're on anything other than the first page,
        // add another page
        if(i < 4){        
          if (i > 0) {
            pdf.addPage();
          }
          //! now we declare that we're working on that page
          pdf.setPage(i + 1);
          //! now we add content to that page!
          pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width * .30), (height * .30));
        }

      }
      pdf.scale(0.15, 0.15);
      //! after the for loop is finished running, we save the pdf.
      base64pdf = pdf.output('datauristring');
      pdf.save(`hoja_${hoja.id}.pdf`);
      sendHoja();
      setGeneratingPDF(false);
    });
  }


  function getPDF2() {
    setErrorSendingHoja(false);
    let doc = new jsPDF('p', 'pt', 'a4');
    setGeneratingPDF(true);
    const collection = document.getElementsByClassName("page-content");
    doc.html(collection[0], {
      callback: (doc) => {
        base64pdf = doc.output('datauristring').split('base64,')[1];
        //doc.save(`hoja_${hoja.id}.pdf`);
        setGeneratingPDF(false);
        sendHoja();
      },
      autoPaging: 'text',
      //width: 650,
      //windowWidth: 595
      html2canvas: {
        scale: 0.42
      }
    });
  }


  return (
    <React.Fragment>
      <div className="page-content" id="factura">
        <MetaTags>
          <title>{`${props.t("Hoja detalle")} ${clave}`} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Hoja detalle")} ${clave}`}
            breadcrumbItem={`${props.t("Hoja detalle")} ${clave}`}
          />
          <Row>
            {!generatingPDF ?
              <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                <button
                  className="btn btn-primary btn-block w-100"
                  type="submit" onClick={getPDF2}
                >
                  {props.t("Generar PDF")}
                </button>
              </div>
              : null}
            {errorSendingHoja ? <Row><Col xs="12" md="4"><Alert color="danger">{props.t("Ha ocurrido un error enviando el PDF")}</Alert></Col></Row> : null}
            {okSendingHoja ? <Row><Col xs="12" md="4"><Alert color="success">{props.t("Se ha enviado correctamente el PDF")}</Alert></Col></Row> : null}

            <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
              <button
                className="btn btn-primary btn-block w-100"
                type="submit" onClick={storeHoja}
              >
                {props.t("Guardar hoja")}
              </button>
            </div>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="text-muted">
                    <Row>
                      <Col xs="12" md="6">
                        {hoja.identificador ?
                          <div className="row mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                            >
                              {props.t("identificador")}
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                value={hoja?.identificador}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                disabled={!editHoja}
                                onChange={(event) => {
                                  handlerChangeForm('identificador', event.target.value);
                                }}
                              />
                            </Col>
                          </div>
                          : null}

                        <div className="row mb-4 d-none">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Usuario")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              value={hoja?.usuario ? hoja?.usuario : user?.clave}
                              disabled={!editHoja}
                              onChange={(event) => {
                                handlerChangeForm('usuario', event.target.value);
                              }}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Ciente")}
                          </Label>
                          <Col sm={8}>
                            <Input type="select" className="form-control"
                              disabled={!editHoja}
                              onChange={(event) => {
                                const { selectedIndex } = event.target;
                                let value = "";
                                if (selectedIndex != 0) {
                                  value = clientes[selectedIndex]?.clave
                                }
                                handlerChangeForm('interlocutor', value);
                              }}>
                              {clientes && clientes.length ? clientes.map((cliente) => (
                                <option key={cliente.clave}>{cliente.nombre}</option>
                              )) : null}
                            </Input>
                          </Col>
                        </div>
                      </Col>

                      <Col xs="12" md="6">
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Información adicional")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="textarea"
                              id="textarea"
                              className="form-control"
                              value={hoja?.informacionAdicional}
                              disabled={!editHoja}
                              onChange={(event) => {
                                handlerChangeForm('informacionAdicional', event.target.value);
                              }}
                            />
                          </Col>
                        </div>
                        {hoja?.identificador ?
                          <div className="row mb-4">
                            <Label
                              htmlFor="horizontal-password-Input"
                              className="col-sm-3 col-form-label"
                            >
                              {props.t("Numero documentos generados")}
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                className="form-control"
                                id="horizontal-password-Input"
                                value={hoja?.numeroDocumentosGenerados}
                                disabled={!editHoja}
                                onChange={(event) => {
                                  handlerChangeForm('numeroDocumentosGenerados', event.target.value);
                                }}
                              />
                            </Col>
                          </div>
                          : null}
                      </Col>
                    </Row>
                    <Row>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {!newMuestra ? <>
            <Row className="mb-4">
              <Col sm="12" md="3">
                <button
                  className="btn btn-primary w-100"
                  type="submit" onClick={() => { showEnvaseForm(); setTypeMuestra('tengoEtiqueta') }}
                >{props.t("Tengo etiqueta")}</button>
              </Col>

              <Col sm="12" md="3">
                <button
                  className="btn btn-primary w-100"
                  type="submit" onClick={() => { showMuestraFormInsert();setTypeMuestra('tengoMuestra') }}
                >{props.t("Tengo número de muestra")}</button>
              </Col>

              <Col sm="12" md="3">
                <button
                  className="btn btn-primary w-100"
                  placeholder={props.t('Número de muestra')}
                  type="submit" onClick={isFromPedido}
                >{props.t("Tengo número de pedido")}</button>
              </Col>

              <Col sm="12" md="3">
                <button
                  className="btn btn-primary w-100"
                  type="submit" onClick={() => { setShowNewMuestra(false); addingMuestra();setTypeMuestra('imprevista') }}
                >{props.t("No tengo información")}</button>
              </Col>

            </Row>
            {showDesplegableMuestras ? <>
              <Row>
                <Col sm={12} md={3}>
                  <Input type="text" className="form-control"
                    onBlur={(event) => {
                      setMuestraChanged(event.target.value);
                    }}>
                  </Input>
                </Col>
                <Col sm="12" md="2">
                  <button
                    className="btn btn-primary w-100"
                    type="submit" onClick={insertMuestraFromSelect}
                  >{props.t("Seleccionar muestra")}</button>
                </Col>
              </Row>
            </> : null}
          </> : null}

          {newMuestra ? <FormMuestrasHoja idhoja={hoja.id} isNewMuestra={true} isFromPedido={hasPedido} typeMuestra={typeMuestra} savedData={addedMuestras}></FormMuestrasHoja> : null}

          {hoja?.muestras?.map((muestra) => (
            <Row key={muestra.id}>
              {muestra.isNewMuestra}
              <FormMuestrasHoja muestra={muestra} idhoja={hoja.id} isNewMuestra={muestra?.isNewMuestra} typeMuestra={muestra?.typeMuestra} savedData={addedMuestras}></FormMuestrasHoja>
            </Row>
          ))}

          {hasEtiqueta ? 
            <div className="text-muted">
            <Row>
              <Col sm={12} md={3}>
                <Input
                  type="text"
                  className="form-control"
                  id="horizontal-password-Input"
                  placeholder={props.t("Envase clave")}
                  onChange={(e) => {
                    setEnvase({envaseClave: e.target.value})
                  }}
                />
              </Col>
              <Col sm="12" md="2">
                  <button
                    className="btn btn-primary w-100"
                    disabled={envase?.envaseClave < 1}
                    type="submit" onClick={insertEnvase}
                  >{props.t("Guardar envase")}</button>
                </Col>
            </Row>
          </div>
          : null}




        </Container>
      </div>

    </React.Fragment>
  )
}

HojaDetalle.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(HojaDetalle)
