import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody} from "reactstrap";

// availity-reactstrap-validation

//redux

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";


// actions
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import i18n from "i18n";

const MaterialEnvase = props => {

  i18n.on('languageChanged', function() {
    window.location.reload(false);
  });

  useEffect(() => {

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/v2.js";
    script.async = true;
    script.charset = "utf-8"
    script.type = "text/javascript";
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      console.log("a");
       if (window.hbspt) {
        hbspt.forms.create({
          region: "na1",
          portalId: "20327574",
          formId: i18next.language == 0 ? "b490616c-a9af-486b-ab84-75a22dc3f1f0" :"499a6771-ac00-4603-a7c5-2288c148455e",
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Material y envase")} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Labaqua" breadcrumbItem={props.t("Material y envase title")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div id="hubspotForm"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


MaterialEnvase.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any
}
export default withTranslation()(withRouter(MaterialEnvase));
