import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  Label,
  Alert,
  InputGroup,
  Table
} from "reactstrap"
import { Prompt } from 'react-router'

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { getAlertas, getCategoriasDocumentos, getClientes, getMuestra, getMuestraIdentificador, getMuestraWithEnvase, getMuestras, getRepositorioDocumentos, getSingleHojasCustodia, getUser, storeHojaCustodia, storeHojaCustodiaAPI, ultimasMuestras, updatehoja, getOfertasVivas, getPedidoByOferta, getConfiguration, getMuestraFromPedido, releaseMuestraFromPedido, checkMuestraExistsDB } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment"
import { Button } from "bootstrap"
import i18next from "i18next"
import FormMuestrasHoja from "../Hoja-detalle/FormMuestrasHoja"

import Html from 'react-pdf-html';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import FormMuestrasEnvases from "../Hoja-detalle/FormMuestrasEnvases";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { set } from "lodash"
import { useHistory } from "react-router-dom";
import FormMuestrasHojaV2 from "./FormMuestrasHojaV2"
import sello from "../../../assets/images/labaqua/sello.jpg";
import logopdf from "../../../assets/images/labaqua/labaqua-logo-pdf.png";




const HojaDetalleV2 = (props) => {

  
  
  const [savingHoja, setSavingHoja] = useState(false);
  const [errorMessageSavingOrSendingHoja, setErrorMessageSavingOrSendingHoja] = useState('');
  const [editingMuestra, setEditingMuestra] = useState(false);
  const [envasesFromLims, setEnvasesFromLims] = useState(true);
  const [isLaboratorio, setIsLaboratorio] = useState(false);
  const [errorRetrievingMuestra, setErrorRetrievingMuestra] = useState(false);
  const [pedido, setPedido] = useState('');
  const [pedidosList, setPedidosList] = useState([]);
  const [ofertasVivas, setOfertasVivas] = useState([]);
  const [muestra, setMuestra] = useState({});
  const [identificadorMuestraEnvase, setIdentificadorMuestraEnvase] = useState('');
  const [typeFormMuestra, setTypeFormMuestra] = useState('');
  const [stepAddingMuestra, setStepAddingMuestra] = useState('0');
  const [secondStepType, setSecondStepType] = useState('');
  const [stateHoja, setStateHoja] = useState('new');
  const [typeMuestra, setTypeMuestra] = useState('');
  const [envase, setEnvase] = useState({envaseClave: ""})
  const [hoja, setHoja] = useState({});
  const [hasEtiqueta, setHasEtiqueta] = useState(false);
  const [muestrasListado, setMuestrasListados] = useState([]);
  const [muestraChanged, setMuestraChanged] = useState();
  const [documents, setDocuments] = useState();
  const [newMuestra, setNewMuestra] = useState(false);
  const [hasPedido, setHasPedido] = useState(false);
  const [showNewMuestra, setShowNewMuestra] = useState(false);
  const [showDesplegableMuestras, setShowDesplegableMuestras] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [editHoja, setEditHoja] = useState(false);
  const [errorSendingHoja, setErrorSendingHoja] = useState(false);
  const [okSendingHoja, setOkSendingHoja] = useState(false);
  const [hojaIsModified, setHojaIsModified] = useState(false);
  const { clave } = useParams();
  const [user, setUser] = useState();
  let history = useHistory();
  let base64pdf = '';
  useEffect(() => {
    initHoja()
    //getData();
  }, [])

  useEffect(() => {
    // Update the document title using the browser API
    if (hojaIsModified) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  });


  async function storePedidoAndEnableButton(clavePedido){
    //TODO Get data from pedido when WS avaialbl
    setPedido(clavePedido);
  }


  async function firstFilterGetData(claveOferta){
     let pedidosResponse = await getPedidoByOferta(claveOferta);
     if(pedidosResponse && pedidosResponse.length){
      setPedidosList(pedidosResponse);
      if(pedidosResponse.length > 0){
        setPedido(pedidosResponse[0].clave)
      }
     }else {
      setPedidosList([]);
     }

  }

  function emptyFormSecondStep(){
    if(secondStepType == 'etiqueta') {
      if(!identificadorMuestraEnvase && identificadorMuestraEnvase.length < 2){
        return true;
      }
      return false;
    }else if('oferta'){
      if(pedido.length > 0){
        return false;
      }
    }
    return true;
  }

  function disableButtonSaveHoja(){
    if(!Array.isArray(hoja.muestras) || !hoja.muestras.length){
      return true;
    }
    return false;
  }

  async function existOnDB(identificador){
    let result = await checkMuestraExistsDB(identificador);
    if(typeof result === 'string'){
      if(hoja.muestras){
        let duplicated = hoja.muestras.some(person => person.identificador == identificador);
        if(duplicated){
          return true;
        }
      }
      return false;
    }
    return true;
  }

  async function getDataPedidoOrMuestra(){
    if(secondStepType == 'etiqueta') {
      if(identificadorMuestraEnvase.length < 2){
        return;
      }
      setErrorRetrievingMuestra(false);

      let existMuestra = await getMuestraIdentificador(identificadorMuestraEnvase);
      let checkDuplicateds = false;
      let sameClient = true;

      if(!existMuestra.clave) {
        existMuestra = await getMuestraWithEnvase(identificadorMuestraEnvase);
      }
      if(existMuestra.clave){
        checkDuplicateds = await existOnDB(existMuestra.identificador);
      }
      if(existMuestra.cliente) {
        if(existMuestra.cliente.clave != hoja.interlocutorClave) {
          sameClient = false;
        }
      }
      if(existMuestra && existMuestra.clave && (existMuestra.estadoClave == 0 ||  existMuestra.estadoClave == 1) &&  !checkDuplicateds && sameClient) {
        existMuestra.parametrosAnalizar = '';
        if(existMuestra.resultados) {
          for(let paramAnalyze of existMuestra.resultados){
            existMuestra.parametrosAnalizar += paramAnalyze.parametro+","; 
          }
          if(existMuestra.parametrosAnalizar.length > 1){
            existMuestra.parametrosAnalizar = existMuestra.parametrosAnalizar.slice(0, -1);
          }
        } else { 
          existMuestra.parametrosAnalizar = '';
        }
        if(existMuestra.envases){
          existMuestra.envasesMuestra = '';
          existMuestra.numeroEnvases = existMuestra.envases.length;
           for(let envaseMuestra of existMuestra.envases){
            existMuestra.envasesMuestra += envaseMuestra.descripcion+","; 
          }
          if(existMuestra.envasesMuestra.length > 1){
            existMuestra.envasesMuestra = existMuestra.envasesMuestra.slice(0, -1);
          }else {
            setEnvasesFromLims(false);
          }
        }
        setMuestra(existMuestra);
        setStepAddingNewMuestra('3');
        setSecondStepType('');
        setTypeFormMuestra('limsData');
        setIdentificadorMuestraEnvase('');
      }else {
        setErrorRetrievingMuestra(true);
      }
    }else if(secondStepType == 'oferta'){
      setErrorRetrievingMuestra(false);
      let muestraResponseFromPedido = await getMuestraFromPedido(pedido);
      let checkDuplicateds = false;
      let sameClient = true;
      if(muestraResponseFromPedido && muestraResponseFromPedido.clave){
        checkDuplicateds = await existOnDB(muestraResponseFromPedido.identificador);
      }
      if(muestraResponseFromPedido.cliente) {
        if(muestraResponseFromPedido.cliente.clave != hoja.interlocutorClave) {
          sameClient = false;
        }
      }
      if(muestraResponseFromPedido && muestraResponseFromPedido.clave && (muestraResponseFromPedido.estadoClave == 0 ||  muestraResponseFromPedido.estadoClave == 1) && !checkDuplicateds && sameClient ) {
        muestraResponseFromPedido.parametrosAnalizar = '';
        if(muestraResponseFromPedido.resultados) {
          for(let paramAnalyze of muestraResponseFromPedido.resultados){
            muestraResponseFromPedido.parametrosAnalizar += paramAnalyze.parametro+","; 
          }
          if(muestraResponseFromPedido.parametrosAnalizar.length > 1){
            muestraResponseFromPedido.parametrosAnalizar = muestraResponseFromPedido.parametrosAnalizar.slice(0, -1);
          }
        } else { 
          muestraResponseFromPedido.parametrosAnalizar = '';
        }
        if(muestraResponseFromPedido.envases){
          muestraResponseFromPedido.envasesMuestra = '';
          muestraResponseFromPedido.numeroEnvases = muestraResponseFromPedido.envases.length;
           for(let envaseMuestra of muestraResponseFromPedido.envases){
            muestraResponseFromPedido.envasesMuestra += envaseMuestra.descripcion+","; 
          }
          if(muestraResponseFromPedido.envasesMuestra.length > 1){
            muestraResponseFromPedido.envasesMuestra = muestraResponseFromPedido.envasesMuestra.slice(0, -1);
          }
          else {
            setEnvasesFromLims(false);
          }
        }
        setMuestra(muestraResponseFromPedido);
        setStepAddingNewMuestra('3');
        setSecondStepType('');
        setTypeFormMuestra('limsData');
        setIdentificadorMuestraEnvase('');
      }else {
        if(muestraResponseFromPedido.clave){
          releaseMuestraFromPedido(muestraResponseFromPedido.clave);
        }
        setErrorRetrievingMuestra(true);
      }
      // TODO -> Call WS to get muestra if return error show into the screen
    }
  }


  function showFormNoData(){
    setStepAddingNewMuestra('3');
    setSecondStepType('');
    setTypeFormMuestra('noData');
  }

  async function changedInterlocutor(idInterlocutor){
    let ofertasVivasResponse = await getOfertasVivas(idInterlocutor);
    if(ofertasVivasResponse && ofertasVivasResponse.length){
      setOfertasVivas(ofertasVivasResponse);
      if(ofertasVivasResponse && ofertasVivasResponse.length > 0)
      firstFilterGetData(ofertasVivasResponse[0]?.clave);
    }else {
      setOfertasVivas([]);
    }
  }

  async function showSecondStep(typeSecondStep){
    setStepAddingNewMuestra('2');
    setSecondStepType(typeSecondStep);
    if(typeSecondStep == 'oferta'){
      let ofertasVivasResponse = await getOfertasVivas(hoja.interlocutorClave);
      if(ofertasVivasResponse && ofertasVivasResponse.length){
        setOfertasVivas(ofertasVivasResponse);
        if(ofertasVivasResponse && ofertasVivasResponse.length > 0)
        firstFilterGetData(ofertasVivasResponse[0]?.clave);
      }else {
        setOfertasVivas([]);
      }

    }
  }
  
  async function saveHojaCustodia(){
    setSavingHoja(true);
    setErrorMessageSavingOrSendingHoja('');
    setOkSendingHoja(false);
    for(let muestra of hoja.muestras){
      muestra.identificadorMuestra = muestra.identificador;
    }
    if(!hoja?.usuario){
      hoja.usuario = user.clave;
    }
    let storedHoja = await storeHojaCustodia(hoja);
    if(storedHoja && storedHoja.id){
      
      let hoja = await getSingleHojasCustodia(storedHoja.id);
      if(hoja && hoja.id){
       setHoja(hoja);
      }
      setHojaIsModified(false);
      setOkSendingHoja(true);
      const location = {
        pathname: "/hoja-detalle/v2/"+storedHoja.id,
      }
      history.push(location)
      history.replace(location)
    }else {
      setErrorMessageSavingOrSendingHoja('Ha ocurrido un error al guardar la muestra');
    }
    setSavingHoja(false);
  }
  

  async function storeMuestra(){

    if(!hoja.muestras) {
      hoja.muestras = [];
    }

    muestra["tipoMuestra"] = typeFormMuestra; 
    if(muestra.identificador){
      muestra.identificadorMuestra = muestra.identificador;
    }
    if(!muestra.instruccionesAnalisis){
      muestra.instruccionesAnalisis = "tomaSimple";
    }
    if(editingMuestra){
      let indexMuestra = hoja.muestras.findIndex(x => x.id === muestra.id);
      hoja.muestras[indexMuestra] = muestra;
    }else {
      hoja.muestras.push(muestra);
    }
    setHoja(hoja);
    setStepAddingMuestra('0');
    setMuestra({});
    setTypeFormMuestra('');
    setSecondStepType('');
    setPedido('');
    setEditHoja(false);
  }

  async function releaseMuestra(){
    if(clave == 'new') {
      if(muestra && muestra.clave){
        releaseMuestraFromPedido(muestra.clave);
      }
    } 
    setStepAddingMuestra('0');
    setMuestra({});
    setTypeFormMuestra('');
    setSecondStepType('');
    setPedido('');
    setEditingMuestra(false);
  }

  function setStepAddingNewMuestra(numStep){
    setOfertasVivas([]);
    setPedidosList([]);
    setStepAddingMuestra(numStep);
    setErrorRetrievingMuestra(false);
  }

  async function initHoja(){
    if(clave != 'new') {
      setStateHoja('edit');
    }
    let clientesResponse = await getClientes();

    if(clave == 'new'){
      setClientes(clientesResponse);
      if(clientesResponse && clientesResponse.length > 0){
        hoja.interlocutorClave = clientesResponse[0].clave;
        setHoja(hoja);
      }
    }else  {
     let hojaResponse = await getSingleHojasCustodia(clave);
     if(hojaResponse && hojaResponse.id){
      setHoja(hojaResponse);
     }else {
      // mostrar error
     }
    }
    setClientes(clientesResponse);
    let user = await getUser();
    if (user) {
      //user.interno = true;
      setUser(user);
    }
    setHojaIsModified(false);
  }

  function isNewHoja(){
    if(hoja.muestras && hoja.muestras.length){
      return true;
    }
    return false;
  }

  function handlerChangeFormHoja(field, value) {
    let formSearchChanged = {...hoja};
    formSearchChanged[field] = value;
    setHoja(formSearchChanged);
    setHojaIsModified(true);
  }

  function handlerChangeFormMuestra(field, value){
    let formSearchChanged = muestra;
    formSearchChanged[field] = value;
    setMuestra(formSearchChanged);
    setHojaIsModified(true);
  }

  function handlerChangeForm(field, value) {
    let formSearchChanged = hoja;
    formSearchChanged[field] = value;
    setHoja(formSearchChanged);
  }

  function updateField(){
    muestra[event.target.name] = event.target.value;
    setMuestra(muestra);
    setHojaIsModified(true);

  }

  function addedMuestras(data){
    if(!hoja.muestras){
      hoja.muestras = [];
    }
    hoja.muestras.push(data);
    setHoja(hoja);
    setNewMuestra(false);
  }

  async function getData() {
    setNewMuestra(false);
    if (clave != 'new') {
      const hojaResponse = await getSingleHojasCustodia(clave);
      setHoja(hojaResponse);
    } else {
      setEditHoja(true);
    }
    let clientesResponse = await getClientes();
    setClientes(clientesResponse);
  }
  /* 
  function printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
  }
  */

  function generatePDF() {
    setOkSendingHoja(false);
    setErrorMessageSavingOrSendingHoja('');
    setErrorSendingHoja(false);
    setGeneratingPDF(true);
    var quotes = document.getElementById("factura");
    html2canvas(quotes).then((canvas) => {
      //! MAKE YOUR PDF
      var pdf = new jsPDF('p', 'pt', 'a4',false , true);

      for (var i = 0; i <= quotes.clientHeight / 980 * 2.5; i++) {
        //! This is all just html2canvas stuff
        var srcImg = canvas;
        var sX = 0;
        var sY = 980 * 2.5 * i; // start 980*2.5 pixels down for every new page
        var sWidth = 1200 * 1.15;
        var sHeight = 980 * 2.5;
        var dX = 0;
        var dY = 0;
        var dWidth = 1200 * 1.15;
        var dHeight = 980 * 1.1;

        window.onePageCanvas = document.createElement("canvas");
        onePageCanvas.setAttribute('width', 1200 * 1.15);
        onePageCanvas.setAttribute('height', 980 * 1.1);
        var ctx = onePageCanvas.getContext('2d');
        // details on this usage of this function: 
        // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
        ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

        // document.body.appendChild(canvas);
        var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

        var width = onePageCanvas.width;
        var height = onePageCanvas.clientHeight;

        //! If we're on anything other than the first page,
        // add another page
        if(i < 4){        
          if (i > 0) {
            pdf.addPage();
          }
          //! now we declare that we're working on that page
          pdf.setPage(i + 1);
          //! now we add content to that page!
          pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width * .30), (height * .30));
        }

      }
      pdf.scale(0.15, 0.15);
      //! after the for loop is finished running, we save the pdf.
      base64pdf = pdf.output('datauristring');
      pdf.save(`hoja_${hoja.id}.pdf`);
      sendHoja();
      setGeneratingPDF(false);
    });
  }


  async function sendHoja(pdfBase64){
    hoja.documentoGenerado = {
        "formato": "pdf",
        "descripcion": hoja.id+".pdf",
        "contenidoB64": pdfBase64
    }
    let sendedHojaResponse = await storeHojaCustodiaAPI(hoja);
    if(sendedHojaResponse?.errors){
      setErrorMessageSavingOrSendingHoja('Ha ocurrido un error al enviar el pdf');
      setOkSendingHoja(false);
    }else {
      setOkSendingHoja(true);
    }
    if(typeof sendedHojaResponse == 'string') {
      if(sendedHojaResponse.toLowerCase().includes('error')){
        setErrorMessageSavingOrSendingHoja(sendedHojaResponse.replace(':',' '));
        setOkSendingHoja(false);
      }else {
        setOkSendingHoja(true);
      }
    }
  }

  async function getPDF2() {
    setErrorRetrievingMuestra(false);
    setStepAddingMuestra(0);
    setGeneratingPDF(true);
    setErrorSendingHoja(false);
    setOkSendingHoja(false);
    setErrorMessageSavingOrSendingHoja('');
    const element = document.getElementsByClassName("page-content")[0];
    // Choose the element and save the PDF for your user.
    /*html2pdf().from(element).outputPdf().then(function(pdf) {
      var totalPages = pdf;
      console.log(totalPages);
      setGeneratingPDF(false);
      base64pdf = btoa(pdf);
      //sendHoja(base64pdf);
    }).save();
    */
    
    // Source HTMLElement or a string containing HTML.
    html2pdf().from(element).set({
      margin: [45, 10, 45, 10], // Set margins if needed
      filename: 'hoja.pdf', // Set the PDF filename
    }).toPdf().get('pdf').then(function (pdf) {
      // Get the jsPDF object from html2pdf
      var totalPages = pdf.internal.getNumberOfPages();
      var generatePdfTime = moment(new Date).format("DD-MM-YY HH:mm");

      // Loop through each page and add additional content
      for (var i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
    
        // Get the additional HTML content for the page
        var pageBreakElements = document.getElementsByClassName('html2pdf__page-break');
        pdf.setFontSize(8);
        pdf.setTextColor(150);
        // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
        pdf.text(pdf.internal.pageSize.getWidth() - 200, pdf.internal.pageSize.getHeight() - 10, 'FECHA/HORA RECEPCIÓN LABORATORIO: '+ generatePdfTime);
        pdf.text(pdf.internal.pageSize.getWidth() - 80, pdf.internal.pageSize.getHeight() - 10, 'RECEPCIÓN EFECTUADA EN LABORATORIO');
         // you can add the line separator as an image, consult the docs below to properly set the place of the image
        pdf.addImage(sello, 'jpg', pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 35, 20, 20)

        //header
        pdf.addImage(logopdf, 'jpg', 10, 10)
        pdf.text(40, 11, 'Calle del Dracma, 16-18');
        pdf.text(40, 14, '03114 Alicante (España)');
        pdf.text(40, 17, 'Tfo: +34 965 106 070');
      }
    
      // Save or download the PDF
      setGeneratingPDF(false);
      base64pdf = btoa(pdf.output());
      sendHoja(base64pdf);
    });
   /* doc.html(element, {
        callback: function(doc) {
          var pageBreakElements = document.getElementsByClassName('html2pdf__page-break');

          // Iterar sobre los elementos y agregar una nueva página en el PDF
          for (var i = 0; i < pageBreakElements.length; i++) {
            console.log("a")
            doc.addPage();
            pdf.html(additionalContent, {
              x: 10, // Set x-position of the content
              y: 10, // Set y-position of the content
            });
            doc.fromHTML(pageBreakElements[i], 10, 10);
          }
      
            // Save the PDF
            doc.save('sample-document.pdf');
            setGeneratingPDF(false);
            setOkSendingHoja(true);
        },
        x: 0,
        y: 15,
        width: 190, //target width in the PDF document
        windowWidth: 650 //window width in CSS pixels
    }); */

    return;

    var opt = {
      image: {
         type: 'webp', 
        quality: 1
       },
       margin: [20, 0, 50, 0],
    };

    html2pdf().set(opt).from(element).toPdf().get('pdf').then((pdf) => {
      var generatePdfTime = moment(new Date).format("DD-MM-YY HH:mm");
      var totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        // set footer to every page
        pdf.setPage(i);
        // set footer font
        pdf.setFontSize(8);
        pdf.setTextColor(150);
        // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
        pdf.text(pdf.internal.pageSize.getWidth() - 200, pdf.internal.pageSize.getHeight() - 10, 'FECHA/HORA RECEPCIÓN LABORATORIO: '+ generatePdfTime);
        pdf.text(pdf.internal.pageSize.getWidth() - 80, pdf.internal.pageSize.getHeight() - 10, 'RECEPCIÓN EFECTUADA EN LABORATORIO');
         // you can add the line separator as an image, consult the docs below to properly set the place of the image
        pdf.addImage(sello, 'jpg', pdf.internal.pageSize.getWidth() - 50, pdf.internal.pageSize.getHeight() - 40, 25, 25)

        //header
        pdf.addImage(logopdf, 'jpg', 10, 10)
        pdf.text(40, 11, 'Calle del Dracma, 16-18');
        pdf.text(40, 14, '03114 Alicante (España)');
        pdf.text(40, 17, 'Tfo: +34 965 106 070');
      }
      base64pdf = btoa(pdf.output());
      //sendHoja(base64pdf);
      setGeneratingPDF(false);
    }).save();
  
  }

  function showFormEdit(idMuestra){
    let existMuestra = hoja.muestras.find(muestra => muestra.id === idMuestra);
    existMuestra.identificador = existMuestra.identificadorMuestra;
    setMuestra(existMuestra);
    setTypeFormMuestra(existMuestra.tipoMuestra);
    setStepAddingNewMuestra('3');
    setSecondStepType('');
    setIdentificadorMuestraEnvase('');
    setEditingMuestra(true);

  }


  return (
    <React.Fragment>
       <Prompt
        when={hojaIsModified}
        message='Es posible que los cambios no se guarden.'
      />
      <div className={`page-content ${generatingPDF ? 'resized-inputs' : ''}`} id="factura" style={generatingPDF ?{background: "white", boxShadow: "none"} : {}}>
        <MetaTags>
          <title>{`${props.t("Hoja detalle")} ${clave}`} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${props.t("Hoja detalle")} ${clave}`}
            breadcrumbItem={`${props.t("Hoja detalle")} ${clave}`}
          />
          <Row>
          {user?.interno ? 
            <>
              {!generatingPDF ?
                <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                  <button
                    className="btn btn-primary btn-block w-100"
                    type="submit" onClick={getPDF2}
                  >
                    {props.t("Generar PDF")}
                  </button>
                </div>
                : null}
            </>
          :
            <></>
          }
           {!generatingPDF ?
            <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
              <button
                className="btn btn-primary btn-block w-100"
                type="submit"
                disabled={(disableButtonSaveHoja() || savingHoja)}
                onClick={saveHojaCustodia}
              >
                {props.t("Guardar hoja")}
              </button>
            </div>
            : <></>}


            {errorMessageSavingOrSendingHoja.length > 1 ? <Row><Col xs="12" md="4"><Alert color="danger">{props.t(errorMessageSavingOrSendingHoja)}</Alert></Col></Row> : null}
            {okSendingHoja ? <Row><Col xs="12" md="4"><Alert color="success">{props.t("La operación se ha realizado correctamente")}</Alert></Col></Row> : null}
            <Col xl="12">
              <Card  style={generatingPDF ?{background: "white", boxShadow: "none"} : {}}>
                <CardBody>
                  <div className="text-muted">
                    <Row>
                      <Col xs="12" md={generatingPDF ? 12 : 6 }>
                        {hoja.identificador ?
                          <div className="row mb-4 d-none">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                            >
                              {props.t("identificador")}
                            </Label>
                            <Col sm={8}>
                              <Input
                                type="text"
                                value={hoja?.identificador}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                disabled={!isNewHoja}
                                onChange={(event) => {
                                  handlerChangeFormHoja('identificador', event.target.value);
                                }}
                              />
                            </Col>
                          </div>
                          : null}

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Ciente")}
                          </Label>
                          <Col sm={8}>
                            {generatingPDF ? 
                            <> {clientes && clientes.length ? clientes.map((cliente) => (
                              (cliente.clave == hoja?.interlocutorClave ? <p>{cliente.nombre}</p> : <></>)
                            )) : null}</> : 
                            
                            <Input type="select" className="form-control"
                            disabled={hoja && hoja.muestras && hoja.muestras.length > 0}
                            value={hoja?.interlocutorClave}
                            onChange={(event) => {
                              console.log(event.target.value)
                              handlerChangeFormHoja('interlocutorClave', event.target.value);
                              changedInterlocutor(event.target.value);
                            }}>
                            {clientes && clientes.length ? clientes.map((cliente) => (
                              <option key={cliente.clave} value={cliente.clave}>{cliente.nombre}</option>
                            )) : null}
                          </Input>}
                           
                          </Col>
                        </div>
                      </Col>

                      <Col xs="12" md={generatingPDF ? 12 : 6} >
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Información adicional")}
                          </Label>
                          <Col sm={generatingPDF ? 8 : 6}>
                            {generatingPDF ? 
                              <p>{hoja?.informacionAdicional}</p> 
                              : 
                              <Input
                              type="textarea"
                              id="textarea"
                              className="form-control"
                              defaultValue={hoja?.informacionAdicional}
                              onChange={(event) => {
                                handlerChangeFormHoja('informacionAdicional', event.target.value);
                              }}
                            />}
                          </Col>
                        </div>

                      </Col>
                    </Row>
                    <Row>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


         
          {stepAddingMuestra == '0' ? 
            <div className="row justify-content-end">
            {!generatingPDF ?
                <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                  <button
                    className="btn btn-primary btn-block w-100"
                    type="submit" onClick={() => setStepAddingNewMuestra('1')}
                  >
                    {props.t("Añadir muestra")}
                  </button>
                </div>
                : null}
            </div>
          : 
            <></>
          }


            {stepAddingMuestra == '1' ? 
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <button
                            className="btn btn-primary btn-block w-100"
                            type="submit" onClick={() => showSecondStep('etiqueta')}
                          >
                            {props.t("Tengo etiqueta")}
                          </button>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <button
                            className="btn btn-primary btn-block w-100"
                            type="submit" onClick={() => showSecondStep('oferta')}
                          >
                            {props.t("Tengo núm. oferta/pedido")}
                          </button>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <button
                            className="btn btn-primary btn-block w-100"
                            type="submit" onClick={showFormNoData}
                          >
                            {props.t("No tengo datos/oferta")}
                          </button>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            : 
              <></>
            }

            
          {stepAddingMuestra == '2' ? 
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                  <div className="text-muted">
                    <Row>
                    {secondStepType == 'etiqueta' ?
                      <>
                        <div className="col-sm-12 col-md-4">
                          <Input
                            type="text"
                            value={identificadorMuestraEnvase}
                            className="form-control mb-4"
                            id="horizontal-firstname-Input"
                            placeholder={props.t('Número de etiqueta')}
                            onChange={(event) => {
                              setIdentificadorMuestraEnvase(event.target.value);
                              //TODO get data from etiqueta
                            }}
                          />
                        </div>
                      </>
                      : <></>}

                      {secondStepType == 'oferta' ?
                      <>
                       <div className="text-muted">
                        <Row className="row mb-4">
                          <Col xs="12" md="6">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Oferta viva")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input type="select" className="form-control"
                                  onChange={(event) => {
                                  const { selectedIndex } = event.target;
                                  let value = "";
                                  value = ofertasVivas[selectedIndex]?.clave
                                  firstFilterGetData(value);
                                }}>
                                  {ofertasVivas && ofertasVivas.length ? ofertasVivas.map((oferta) => (
                                    <option key={oferta.clave} value={oferta.clave}>{oferta.descripcion}</option>
                                  )) : null}
                              </Input>
                            </Col>
                          </div>
                          </Col>
                          <Col xs="12" md="6">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Pedido")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input type="select" className="form-control"
                                  disabled={pedidosList.length == 0}
                                  onChange={(event) => {
                                    const { selectedIndex } = event.target;
                                    let value = "";
                                    value = pedidosList[selectedIndex]?.clave
                                    storePedidoAndEnableButton(value);
                                  }}>
                                    {pedidosList && pedidosList.length ? pedidosList.map((pedido) => (
                                      <option key={pedido.clave} value={pedido.clave}>{pedido.descripcion}</option>
                                    )) : null}
                                </Input>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                       </div>
                      </>
                      : <></>}
                    </Row>
                    <Row>
                      <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                        <button
                          className="btn btn-primary btn-block w-100"
                          type="submit" onClick={() => setStepAddingNewMuestra('0')}
                        >
                          {props.t("Cancelar")}
                        </button>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                        <button
                          className="btn btn-primary btn-block w-100"
                          type="submit" onClick={() => getDataPedidoOrMuestra()}
                          disabled={(emptyFormSecondStep())}
                        >
                          {props.t("Siguiente")}
                        </button>
                      </div>
                    </Row>
                    {errorRetrievingMuestra ? 
                     <Row>
                      <Col xs="12" md="4">
                        <Alert color="danger">
                          {props.t('No se han podido recuperar los datos de la muestra introducida')}
                        </Alert>
                      </Col>
                      </Row>
                      : <></>
                    }
                   
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : 
            <></>
            }

          {stepAddingMuestra == '3' ? 
           <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    {typeFormMuestra == 'noData' ? 
                      <div className="text-muted">
                        <Row className="row mb-4">
                          <Col xs="12" md="6">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Denominacion")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.denominacion}
                                  name='denominacion'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Tipo analisis")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.tipoDeAnalisis}
                                  name='tipoDeAnalisis'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Parametros a analizar")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.parametrosAnalizar}
                                  name='parametrosAnalizar'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Envases muestra")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.envasesMuestra}
                                  name='envasesMuestra'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                                <p>{props.t('envases_help_msg')}</p>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Nº envases")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="number"
                                  defaultValue={muestra?.numeroEnvases}
                                  name='numeroEnvases'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                          </Col>
                          <Col xs="12" md="6">
                            <div className="row mb-4">
                                {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Fecha toma")}
                              </Label>
                              <Col sm={8} md={6}>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="YYYY/MM/DD"
                                    options={{
                                      altInput: true,
                                      altFormat: "d-m-y H:i",
                                      enableTime: true,
                                      allowInput: true,
                                      locale: {
                                        ...Spanish
                                      }
                                    }}
                                    readOnly={false}
                                    value={muestra.fechaToma}
                                    name="fechaToma"
                                    onChange={(selectedDates, dateStr, instance) => { handlerChangeFormMuestra('fechaToma', selectedDates[0].toISOString()) }}
                                  />
                                </InputGroup>
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones informe")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.informacionClienteInforme}
                                  name='informacionClienteInforme'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones laboratorio")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.observacionesLaboratorio}
                                  name='observacionesLaboratorio'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            {user?.interno ? 
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones estado envases Labaqua")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.observacionesEstadoLaboratorio}
                                  name='observacionesEstadoLaboratorio'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            : <></>}
                          </Col>
                        </Row>

                        <Row className="row mb-4">
                          <Col xs={12}>
                            <p>{props.t('hoja_no_data_msg')}</p>
                          </Col>
                        </Row>
                      </div>
                    
                    : <></>}
                    {typeFormMuestra == 'limsData' ? 
                    <>
                       <div className="text-muted">
                        <Row className="row mb-4">
                          <Col xs="12" md="6">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Número Muestra")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.identificador}
                                  name='identificador'
                                  className="form-control"
                                  disabled
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Oferta")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.ofertaDescripcion}
                                  name='oferta'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                  disabled
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Pedido")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.pedidoDescripcion}
                                  name='Pedido'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                  disabled
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Punto de muestreo")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.puntoDeMuestreo}
                                  name='puntoDeMuestreo'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                  disabled
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Tipo de analisis")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.tipoDeAnalisis}
                                  name='tipoDeAnalisis'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                  disabled
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Parámetros a analizar")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.parametrosAnalizar}
                                  name='parametrosAnalizar'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                  disabled
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Envases muestra")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.envasesMuestra}
                                  name='envasesMuestra'
                                  className="form-control"
                                  disabled={envasesFromLims}
                                  id="horizontal-firstname-Inputa"
                                />
                                <p>{props.t('envases_help_msg')}</p>

                              </Col>
                            </div>
                            <div className="row mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Nº envases")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="number"
                                  defaultValue={muestra?.numeroEnvases}
                                  disabled={envasesFromLims}
                                  name='numeroEnvases'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                          </Col>
                          <Col xs="12" md="6">
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Denominacion")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.denominacion}
                                  name='denominacion'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                                {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                                <Label
                                  htmlFor="horizontal-email-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  {props.t("Fecha toma")}
                                </Label>
                                <Col sm={8} md={6}>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="YYYY/MM/DD"
                                    options={{
                                      altInput: true,
                                      altFormat: "d-m-y H:i",                                      enableTime: true,
                                      allowInput: true,
                                      locale: {
                                        ...Spanish
                                      }
                                    }}
                                    readOnly={false}
                                    value={muestra?.fechaToma}
                                    name="fechaToma"
                                    onChange={(selectedDates, dateStr, instance) => { handlerChangeFormMuestra('fechaToma', selectedDates[0].toISOString()) }}
                                  />
                                </InputGroup>
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Instrucciones análisis")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input type="select" className="form-control" 
                                  defaultValue={muestra?.instruccionesAnalisis}
                                  onChange={(event) => {
                                  const { selectedIndex } = event.target;
                                  handlerChangeFormMuestra('instruccionesAnalisis', event.target.value);
                                }}>
                                  <option key="tomaSimple" value="tomaSimple">{props.t("Toma simple")}</option>
                                  <option key="tomaCompuesta" value="tomaCompuesta">{props.t("Toma compuesta")}</option>
                                </Input>
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Parámetros extra")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.parametrosExtra}
                                  name='parametrosExtra'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones informe")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.informacionClienteInforme}
                                  name='informacionClienteInforme'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>

                            <div className="row mb-4">
                              {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                {props.t("Observaciones laboratorio")}
                              </Label>
                              <Col sm={8} md={6}>
                                <Input
                                  onChange={updateField}
                                  type="text"
                                  defaultValue={muestra?.observacionesLaboratorio}
                                  name='observacionesLaboratorio'
                                  className="form-control"
                                  id="horizontal-firstname-Inputa"
                                />
                              </Col>
                            </div>
                            {user?.interno ? 
                             <div className="row mb-4">
                             {/* TODO Editar els desplegables amb lo que ens vingui de l'API */}
                             <Label
                               htmlFor="horizontal-email-Input"
                               className="col-sm-3 col-form-label"
                             >
                               {props.t("Observaciones estado envases Labaqua")}
                             </Label>
                             <Col sm={8} md={6}>
                               <Input
                                 onChange={updateField}
                                 type="text"
                                 defaultValue={muestra?.observacionesEstadoLaboratorio}
                                 name='observacionesEstadoLaboratorio'
                                 className="form-control"
                                 id="horizontal-firstname-Inputa"
                               />
                             </Col>
                           </div>
                           :<></>}
                           
                          </Col>
                        </Row>
                      </div>
                    </>
                    : 
                      <></> 
                    }
                  </Row>
                  <div className="row justify-content-end">
                    <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                      <button
                        className="btn btn-primary btn-block w-100"
                        type="submit" onClick={() => {
                          releaseMuestra();
                        }}
                      >
                        {props.t("Cancelar muestra")}
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-2 mb-4">
                      <button
                        className="btn btn-primary btn-block w-100"
                        type="submit" onClick={() => {
                          storeMuestra();
                        }}
                      >
                        {props.t("Guardar muestra")}
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
           </Row>          
          : 
            <></>
          }

        {/* 
          {hoja?.muestras?.map((muestra) => (
            <Row key={muestra.id}>
              {muestra.isNewMuestra}
              <FormMuestrasHoja muestra={muestra} idhoja={hoja.id} isNewMuestra={muestra?.isNewMuestra} typeMuestra={muestra?.typeMuestra} savedData={addedMuestras}></FormMuestrasHoja>
            </Row>
          ))}
        */}
       


        <>
          {hoja?.muestras ?
            <>
              <Row>
                <Col xl="12">
                  <Card style={generatingPDF == true ? {background: "white", boxShadow: "none", marginBottom: 0} : {}}>
                    <CardBody>
                      <h4 className="font-size-18">{props.t('Muestras')}</h4>
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>{props.t("Nº de muestra")}</th>
                              <th>{props.t("Fecha de Toma")}</th>
                              <th>{props.t("Denominación")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {hoja?.muestras?.map((muestra) => (
                              <tr key={muestra.id}>
                                <td className="pointer" onClick={() => {showFormEdit(muestra?.id)}}  style={generatingPDF == true ? {background: "white", boxShadow: "none", marginBottom: 0} : {}}>{muestra?.identificadorMuestra}</td>
                                <td style={generatingPDF == true ? {background: "white", boxShadow: "none", marginBottom: 0} : {}}>{muestra && muestra.fechaToma ? moment(muestra?.fechaToma).format("DD-MM-YY HH:mm") : ''}</td>
                                <td style={generatingPDF == true ? {background: "white", boxShadow: "none", marginBottom: 0} : {}}>{muestra?.denominacion}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>  
            </>
        : <></>}
        </>
     
         {generatingPDF == true ? 
         hoja.muestras?.map((muestra, index) => (
          <>
            <div className={index == 0 ? 'html2pdf__page-break' : 'html2pdf__page-break'}></div>
            <Row key={muestra.id} >
              <h4 className="mb-0 font-size-18 mt-80-hoja">Muestra</h4>
              <FormMuestrasHojaV2 muestra={muestra} typeFormMuestra={muestra.tipoMuestra} user={user}></FormMuestrasHojaV2>
            </Row>
          </>
          ))
          :
        <></> 
        }
        

        </Container>
      </div>

    </React.Fragment>
  )
}

HojaDetalleV2.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(HojaDetalleV2)
