import React from 'react'
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from "prop-types"

/*import AlertaIcon from '../../assets/images/icons/AlertaIcon';
import AlertaIconActive from '../../assets/images/icons/AlertaIconActive'; */
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getDefaultsSizePages } from 'helpers/generic_helper';
import { Link } from 'react-router-dom';
import { downloadFileNode, storeFileCache } from 'helpers/LabaquaHelper';
/*
interface PropsInterface extends WithTranslation{
    documentos: Muestra[]
    alertas?: AlertaData[]
    size: number
    pagination?: boolean;
}
interface StateInterface {
    currentPage: number
}
*/

const ListDocumentos = (props) => {

    const size = props.size;
    const documentos = props.documentos;

    const defaultSorted = [{
        dataField: 'fechaAprobado',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: size,
        totalSize: documentos.length,
        custom: true,
    }

    const columns = [
        {
            dataField: 'contenidoB64',
            text: '',
            sort: false,
            formatter: (cell, row) => { return getTypeFile(row) },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { downloadFile(row)},
              }
        },
        {
            dataField: 'descripcion',
            text: props.t('Descripcion'),
            value: "",
            sort: true,
        },
        {
            dataField: 'fechaAprobado',
            text: props.t('Fecha Aprobado'),
            text: 'Fecha aprobado',
            sort: true
        }, {
            dataField: 'numeroInforme',
            text: props.t('Numero Informe'),
            sort: true
        }
    ];

    function getTypeFile(doc) {
        let component = <i className="fas fa-file external-event"></i>;
        if (doc.formato.includes("pdf")) {
            component = <i className="fas fa-file-pdf external-event"></i>
        } else if (doc.formato.includes("xls")) {
            component = <i className="fas fa-file-excel external-event"></i>
        }
        return <React.Fragment>{component}</React.Fragment>
    }

    function downloadFile(doc) {
        const filename = `${doc.descripcion}_${doc.clave}_${doc.descripcion}.${doc.formato.replace(/ /g,'')}`;
        const href = 'data:application/octet-stream;base64,' + doc.contenidoB64;
        const downloadLink = document.createElement("a");
        downloadLink.href = href;
        downloadLink.download = filename;
        downloadLink.click();

       const storeObjFileCache = {
            base64: doc.contenidoB64,
            claveDocumento: doc.clave
       };

       storeFileCache(storeObjFileCache);

        const downloadObjectNode = {
            clave: doc.clave,
            filename: `${doc.descripcion}_${doc.clave}_${doc.descripcion}`,
            extension: doc.formato.replace(/ /g,'')
        };

        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+new URLSearchParams(downloadObjectNode).toString();
            window.history.pushState({path:newurl},'',newurl);
        }
        //downloadFileNode(new URLSearchParams(downloadObjectNode).toString())
    }

    const sizePerPageList = getDefaultsSizePages(documentos.length);

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">{props.t("Documentos")}</h4>
                        </div>
                    </Col>
                </Row>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='clave'
                    columns={columns}
                    data={documentos}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='clave'
                            columns={columns}
                            data={documentos}
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap"
                                                    }
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                    )
                    }</PaginationProvider>

            </CardBody>
        </Card>
    )
}

ListDocumentos.propTypes = {
    t: PropTypes.any,
    size: PropTypes.number,
    documentos: PropTypes.any
}
export default withTranslation()(ListDocumentos)