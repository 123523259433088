import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Alert,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import i18next from "i18next";
import i18n from "i18n";
//redux
import { getAlertas, getAlertasMuestra, getMuestra, updateMuestra, getPuntoMuestreo, padTo2Digits } from "helpers/LabaquaHelper"
import { Link, useParams } from "react-router-dom"
import ListResultados from "components/Labaqua/Muestra/ListadosMuestra/ListResultados"
import ListDocumentos from "components/Labaqua/Muestra/ListadosMuestra/ListDocumentos"
import ListAlertas from "components/Labaqua/Muestra/ListadosMuestra/ListAlertas"
import moment from "moment"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { isObject } from "lodash"

const MuestraDetalle = props => {
  i18n.on('languageChanged', function(value) {
    setLanguage(value);
  });

  let fechaRecepcionConverted = null;

  const [errorUpdating, setErrorUpdating] = useState('');
  const [language, setLanguage] = useState(i18next.language);
  const [errorRetrieving, setErrorRetrieving] = useState(null);
  const [muestra, setMuestra] = useState()
  const [puntos, setPuntos] = useState()
  const [alertas, setAlertas] = useState()
  const [disabledEditMuestra, setDisabledEditMuestra] = useState(true)
  const [savingMuestra, setSavingMuestra] = useState(false)
  const [permisosMuestra, setPermisosMuestra] = useState(true)
  const { clave } = useParams();
  useEffect(() => {
    getData();
  }, [])
  

  async function getData() {
    const muestraResponse = await getMuestra(clave);
    if (muestraResponse) {
      if (typeof muestraResponse == 'string') {
        setErrorRetrieving(muestraResponse);
      } else {
        if (muestraResponse && muestraResponse.fechaRecepcion) {
          muestraResponse.fechaRecepcion = moment(muestraResponse.fechaRecepcion).format("DD-MM-YY hh:mm");
          //muestraResponse.fechaRecepcion = new Date(muestraResponse.fechaRecepcion);
        }
    
        if (muestraResponse && muestraResponse.fechaToma) {
        //muestraResponse.fechaToma = moment(muestraResponse.fechaToma).format("DD-MM-YY hh:mm");
          muestraResponse.fechaToma = new Date(muestraResponse.fechaToma).toISOString();
        }

        setMuestra(muestraResponse);
      }
    }
    console.log("puntos");
    let puntosResponse = await getPuntoMuestreo(language);
    setPuntos(puntosResponse);

    let alertasResponse = await getAlertasMuestra();
    if (alertasResponse) {
      if (typeof alertasResponse == 'string') {
        setErrorRetrieving(alertasResponse);
      } else {
        alertasResponse = alertasResponse.filter((alerta) => { return alerta.muestraClave == clave })
        setAlertas(alertasResponse);
      }
    }
   
  }

  function enableEditMuestra(value) {
    setDisabledEditMuestra(value);
    setPermisosMuestra(true);
  }

  async function saveMuestra() {
    setErrorUpdating('');
    setPermisosMuestra(true);
    setSavingMuestra(true);
    const muestraSave = {
      "clave": muestra.clave,
      "denominacion": muestra.denominacion,
      "referenciaCliente": muestra.referenciaCliente,
      "fechaToma": muestra.fechaToma,
      "puntoMuestreoClave": muestra.puntoDeMuestreoClave,
      "informacionClienteInforme": muestra.informacionClienteInforme
    }
    if (muestra.documentos.length == 0 && (muestra.estadoClave == 2 || muestra.estadoClave == 1 || muestra.estadoClave == 0 || muestra.estadoClave == null)) {
      const result = await updateMuestra(muestraSave);
      if(isObject(result)){
        setErrorUpdating('errorSavingMuestra');
      }
      setPermisosMuestra(true);
    } else {
      setPermisosMuestra(false);
    }
    setSavingMuestra(false)
    setDisabledEditMuestra(true);
    // 434IV472
  }

  async function updateField(event) {
    muestra[event.target.name] = event.target.value;
    setMuestra(muestra);
  }

  async function updateDate(field, value) {
    muestra[field] = value;
    setMuestra(muestra);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{`${props.t("Muestra ")} ${muestra?.identificador}`} | Labaqua</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {muestra && muestra.identificador ?  
          <Breadcrumbs
            title={`${props.t("Muestra")} ${muestra?.identificador}`}
            breadcrumbItem={`${props.t("Muestra")} ${muestra?.identificador}`}
          />
          : null}
         
          {errorRetrieving ?
            <Row>
              <Col xs="12" md="6">
                <Alert color="danger">
                  {errorRetrieving}
                </Alert>

              </Col>
            </Row> : null}


          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="text-muted">
                    <Row>
                      <Col xs="12" md="6">
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Inputa"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("N de analisis")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              defaultValue={muestra?.identificador}
                              name='identificador'
                              className="form-control"
                              id="horizontal-firstname-Inputa"
                              disabled
                            />
                          </Col>
                        </div>
                        
                        <div className="row mb-4">
                       
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Order description")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              name='pedidoDescripcion'
                              defaultValue={muestra?.pedidoDescripcion}
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Oferta description")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              name='ofertaDescripcion'
                              defaultValue={muestra?.ofertaDescripcion}
                              disabled
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Type analisis")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              name='tipoDeAnalisis'
                              defaultValue={muestra?.tipoDeAnalisis}
                              disabled
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Status")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              defaultValue={muestra?.estadoTexto}
                              name='estado'
                              disabled
                            />
                          </Col>
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Denomination")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              defaultValue={muestra?.denominacion}
                              name='denominacion'
                              className="form-control"
                              id="horizontal-firstname-Input"
                              disabled={disabledEditMuestra}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Sampling point")}
                          </Label>
                          <Col sm={8}>
                            
                            <Input
                              onChange={updateField}
                              type="text"
                              defaultValue={muestra?.puntoDeMuestreo}
                              name='puntoDeMuestreo'
                              className="form-control"
                              id="horizontal-firstname-Input"
                              disabled
                            />
                            {/*
                              <Input
                                type="select" 
                                className="form-control" 
                                name='puntoDeMuestreoClave'
                                disabled={disabledEditMuestra}
                                value={muestra?.puntoDeMuestreoClave}
                                onChange={(event) => {
                                  const { selectedIndex } = event.target;
                                  let value = "";
                                  if(selectedIndex != 0){
                                    value = puntos[selectedIndex-1]?.clave
                                  }
                                  event.target.value = value;
                                  updateField(event)
                                }}>
                                  {puntos && puntos.length ? puntos.map((punto) => (
                                    <option key={punto.clave}>{punto.descripcion}</option>
                                  )) :  null}
                              </Input>
                             */}
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Referencia cliente")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              defaultValue={muestra?.referenciaCliente}
                              name='referenciaCliente'
                              disabled={disabledEditMuestra}
                            />
                          </Col>
                        </div>
                        {muestra ? 
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Fecha Toma")}
                          </Label>
                          <Col sm={8}>
                          <InputGroup>
                          <Flatpickr
                              className={`${disabledEditMuestra}-read-only form-control d-block`}
                              placeholder="YYYY/MM/DD"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y H:i K",
                                enableTime: true,
                                defaultDate: muestra?.fechaToma,
                                disabled: {disabledEditMuestra},
                                altInputClass: "true-read-only form-control d-block form-control input",
                                readOnly: {disabledEditMuestra},
                                locale: {
                                  ...Spanish
                                }
                              }}
                              onChange={(selectedDates, dateStr, instance) => { updateDate('fechaToma', selectedDates[0].toISOString()) }}
                            />
                          </InputGroup>
                        </Col>
                        </div>
                        : <></>}
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-3 col-form-label"
                          >
                            {props.t("Fecha Recepcion")}
                          </Label>
                          <Col sm={8}>
                            <Input
                              onChange={updateField}
                              type="text"
                              className="form-control"
                              id="horizontal-password-Input"
                              defaultValue={muestra?.fechaRecepcion}
                              name='fechaRecepcion'
                              disabled
                            />
                          </Col>
                        </div>
                      </Col>
                    </Row>

                    {!permisosMuestra ?
                      <Row>
                        <Col xs={12} md={4}>
                          <Alert color="danger">
                            {props.t('NotPermisionSaveMuestra')}
                          </Alert>
                        </Col>
                      </Row> : null}

                      {errorUpdating.length > 0 ? 
                      <Row>
                        
                        <Col xs={12} md={4}>
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {props.t(errorUpdating)}
                          </Alert>
                        </Col>
                      </Row> : <></>}
                    <Row>
                      {disabledEditMuestra ?
                      <>
                        {muestra?.documentos?.length == 0 && (muestra?.estadoClave == 2 || muestra?.estadoClave == 1 || muestra?.estadoClave == 0 || muestra?.estadoClave == null) ? <>
                          <div className="mt-3 d-grid col-xs-12 col-md-2">
                            <button
                              className="btn btn-warning btn-block btn-labaqua"
                              type="submit"
                              onClick={() => enableEditMuestra(false)}
                            >
                              {props.t("Edit")}
                            </button>
                          </div>
                        </> :<></>}
                      </>
                        :
                        <>
                          <div className="mt-3 d-grid col-xs-12 col-md-2">
                            <button
                              className="btn btn-secondary btn-block"
                              type="submit"
                              onClick={() => enableEditMuestra(true)}
                            >
                              {props.t("Cancelar")}
                            </button>
                          </div>

                          <div className="mt-3 d-grid col-xs-12 col-md-2">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={savingMuestra}
                              onClick={() => saveMuestra(true)}
                            >
                              {props.t("Guardar")}
                            </button>
                          </div>
                        </>
                      }

                    </Row>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

          {muestra && muestra.resultados.length ?
            <Row>
              <Col lg="12">
                <ListResultados size={15} resultados={muestra.resultados} />
              </Col>
            </Row>
            : null}

          {muestra && muestra.documentos.length ?
            <Row>
              <Col lg="12">
                <ListDocumentos size={10} documentos={muestra.documentos} />
              </Col>
            </Row>
            : null}

          {alertas && alertas.length ?
            <Row>
              <Col lg="12">
                <ListAlertas size={10} alertas={alertas} />
              </Col>
            </Row>
            : null}
        </Container>
      </div>

    </React.Fragment>
  )
}

MuestraDetalle.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(MuestraDetalle)
